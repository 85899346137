import React, { Fragment } from 'react'
import { Row } from 'react-bootstrap'
import MemberCard from './MemberCard'

export default function MemberGrid({ members, specialties }) {
  return (
    <Fragment>
      <Row className="container-img-team color-blue">
        {members &&
          members.map((member) => (
            <MemberCard
              key={member.id}
              member={member}
              specialties={specialties}
            />
          ))
        }
      </Row>
    </Fragment>
  )
}
