import React from "react"
import  Container from 'react-bootstrap/Container'

const PageNotFound = () => (
//Fragment
  <>
    <Container className="container-error-page">
        <div className="container-img-error-page" align="center">
            <img src="../images/notFound.png" width='400px' height='400px' alt="page not found 404" />
        </div>
    </Container>
  </>
)
export default PageNotFound