import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import HeaderDashboard from '../../header/HeaderDashboard';
import User from '../../login/User';
import FooterDashboard from '../../footer/FooterDashboard';
import EditContact from './EditContact';
import { getContactList } from './ContactApi';
import DeleteContact from './DeleteContact';
import AddContact from './AddContact';
import ReactPaginate from 'react-paginate';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ContactList() {
    const [contactList, setContactList] = useState([]);
    const [currentContact, setCurrentContact] = useState({});
    const [showAdditionModal, setShowAdditionModal] = useState(false);
    const [showEditionModal, setShowEditionModal] = useState(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getContactList().then((contacts) => setContactList(contacts))
    }, []);

    useEffect(() => {
        if (!showAdditionModal) getContactList().then((contacts) => setContactList(contacts))
        if (!showEditionModal) getContactList().then((contacts) => setContactList(contacts))
        if (!showDeletionModal) getContactList().then((contacts) => setContactList(contacts))
    }, [showAdditionModal, showEditionModal, showDeletionModal])

    const handleValuesToEdit = (contactId) => {
        setCurrentContact(contactList.find((contact) => contact.id === contactId))
        setShowEditionModal(true)
    }

    const handleValuesToAdd = () => {
        setShowAdditionModal(true)
    }

    const handleValuesToRemove = (contactId) => {
        setCurrentContact(contactList.find((contact) => contact.id === contactId))
        setShowDeletionModal(true)
    }

    const handlePageClick = (e) => {
        setOffset(e.selected * 15)
    };

    return (
        <div style={{ margin: '0' }}>
            <Container fluid>
                <Row>
                    <Col sm={12} md={12} lg={2}>
                        <HeaderDashboard />
                    </Col>
                    <Col sm={12} md={12} lg={10}>
                        <User />
                        <div className="container-datatable">
                            <div className="container-table">
                                <div className='d-flex justify-content-between align-items-center full-width'>
                                    <div>
                                        <img src="../../assets/images/add.png" alt="icon add" onClick={handleValuesToAdd} className='clickable' />
                                    </div>
                                    <div>
                                        <ExcelFile 
                                        filename={'Abonés-'+new Date().toISOString().split('T')[0]}
                                        element={<div className='bt-green-def d-flex align-items-center'><i className="material-icons">file_download</i>&nbsp;Exporter les abonnés</div>}>
                                            <ExcelSheet data={contactList.map((e) => { return { ...e, name: e.last_name + ' ' + e.first_name } })} name="Abonés">
                                                <ExcelColumn label="Date" value="date" />
                                                <ExcelColumn label="Nom" value="name" />
                                                <ExcelColumn label="Email" value="email" />
                                                <ExcelColumn label="Téléphone" value="phone_number" />
                                                <ExcelColumn label="Ville" value="city" />
                                                <ExcelColumn label="Message" value="message" />
                                                <ExcelColumn label="Abonné(e) à la Newsletter" value={(col) => col.subscribed ? "Oui" : "Non"} />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>
                                </div>
                                <table className='full-width'>
                                    <thead>
                                        <tr className="container-column-sm">
                                            <th className="column-datatable col-10" key={1}>Date</th>
                                            <th className="column-datatable col-10" key={2}>Nom</th>
                                            <th className="column-datatable col-10" key={3}>Email</th>
                                            <th className="column-datatable col-10" key={4}>Téléphone</th>
                                            <th className="column-datatable col-10" key={5}>Ville</th>
                                            <th className="column-datatable col-20" key={6}>Message</th>
                                            <th className="column-datatable col-10" key={7}>Abonné(e) à la Newsletter</th>
                                            <th className="column-datatable col-15" key={8}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contactList.slice(offset, offset + 15).map((contact, index) => {
                                            return (
                                                <tr className="row-datatable" key={index}>
                                                    <td>{contact.date}</td>
                                                    <td>{contact.last_name}&nbsp;{contact.first_name}</td>
                                                    <td>{contact.email}</td>
                                                    <td>{contact.phone_number}</td>
                                                    <td>{contact.city}</td>
                                                    <td>{contact.message}</td>
                                                    <td>{(contact.subscribed === 'Y') ? 'Oui' : 'Non'}</td>
                                                    <td>
                                                        <span className='clickable action-icon' onClick={() => handleValuesToEdit(contact.id)}><i className="material-icons size-icono">edit</i></span>
                                                        <span className='clickable action-icon' onClick={() => handleValuesToRemove(contact.id)}><i className="material-icons size-icono">delete</i></span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="container-pagination">
                                <ReactPaginate
                                    previousLabel={"Précédent"}
                                    nextLabel={"Suivant"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil(contactList.length / 15)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick.bind(this)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FooterDashboard />
                    </Col>
                </Row>
            </Container>

            <AddContact show={showAdditionModal} closeFromChild={() => setShowAdditionModal(false)}></AddContact>
            <EditContact show={showEditionModal} contact={currentContact} closeFromChild={() => setShowEditionModal(false)}></EditContact>
            <DeleteContact show={showDeletionModal} contact={currentContact} closeFromChild={() => setShowDeletionModal(false)}></DeleteContact>
        </div>
    )
}
