
import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import * as Yup from 'yup';
import Alert from 'react-bootstrap/Alert'
import { Link } from 'react-router-dom'

import API from '../axios';


const validateSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(2, 'Trop court!')
        .max(16, 'Trop long!')
        .required('Requis'),
    lastname: Yup.string()
        .min(2, 'Trop court!')
        .max(16, 'Trop long!')
        .required('Requis'),
    telephone: Yup.number()
        .positive('Numéro invalide')
        .required('Requis'),
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    city: Yup.string()
        .required('Requis')
        .min(2, 'Trop court!')
        .max(70, 'Trop long!'),
    typeConsult: Yup.string()
        .required('Requis'),
    comment: Yup.string()
        .required('Requis')
        .min(10, 'Trop court!')
        .max(500, 'Trop long!'),
    confidentialPolity: Yup.boolean()
        .oneOf([true], "La politique confidentielle doit être acceptée.")
        .required('Requis'),
});

export default class FormContact extends Component {

    constructor(props) {
        super(props);
        this.alertSuccess = React.createRef();
        this.alertError = React.createRef();
        this.state = {
            response: []
        }

    }

    componentDidMount() {
        this.alertSuccess.current.style.display = 'none';
        this.alertError.current.style.display = 'none';
    }

    render() {
        return (
            <div>
                <Alert variant="success" ref={this.alertSuccess}>
                    Succès, envoi d'informations!
                </Alert>
                <Alert variant="danger" ref={this.alertError}>
                    Erreur 500!
                </Alert>
                <Formik
                    initialValues={{
                        firstname: '',
                        lastname: '',
                        telephone: '',
                        email: '',
                        city: '',
                        typeConsult: '',
                        comment: '',
                        newsletter: false,
                        confidentialPolity: false,
                    }}
                    validationSchema={validateSchema}
                    onSubmit={(values, { resetForm }) => {
                        var resNewsletter;
                        let self = this;

                        if (values.newsletter !== true) {
                            resNewsletter = "N";
                        } else {
                            resNewsletter = "Y";
                        }

                        API.get('/user/get/byEmail?email=' + values.email)
                            .then(function (response) {
                                API({
                                    method: 'post', url: 'user/add/message', data: {
                                        reason: parseInt(values.typeConsult),
                                        userId: parseInt(response.data.data.id),
                                        comment: values.comment
                                    },
                                    headers: { 'Content-Type': 'application/json' }
                                }).then(res => {
                                    if (res.status === 200) {
                                        resetForm();
                                        self.alertSuccess.current.style.display = 'block';
                                    } else {
                                        self.alertError.current.style.display = 'block';
                                    }
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            }).catch(function (error) {
                                API({
                                    method: 'post', url: 'user/add/contact', data: {
                                        firstName: values.firstname,
                                        lastName: values.lastname,
                                        email: values.email,
                                        city: values.city,
                                        subscribed: resNewsletter,
                                        phoneNumber: values.telephone,
                                        reason: parseInt(values.typeConsult),
                                        comment: values.comment
                                    },
                                    headers: { 'Content-Type': 'application/json' }
                                }).then(res => {
                                    if (res.status === 200) {
                                        resetForm();
                                        self.alertSuccess.current.style.display = 'block';
                                    } else {
                                        self.alertError.current.style.display = 'block';
                                    }
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            });
                    }}
                >
                    {
                        props => (
                            <Container>
                                <form onSubmit={props.handleSubmit}>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group m-2">
                                                <input
                                                    id="firstname"
                                                    name="firstname"
                                                    placeholder="Nom"
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    value={props.values.firstname}
                                                    className="form-control"
                                                />
                                                {props.errors.firstname ? <div style={{ color: 'red' }}>{props.errors.firstname}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group m-2">
                                                <input
                                                    id="lastname"
                                                    name="lastname"
                                                    placeholder="Prénom"
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    value={props.values.lastname}
                                                    className="form-control"
                                                />
                                                {props.errors.lastname ? <div style={{ color: 'red' }}>{props.errors.lastname}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group m-2">
                                                <input
                                                    id="telephone"
                                                    name="telephone"
                                                    placeholder="Numéro de Téléphone"
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    value={props.values.telephone}
                                                    className="form-control"
                                                />
                                                {props.errors.telephone ? <div style={{ color: 'red' }}>{props.errors.telephone}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group m-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    type="email"
                                                    onChange={props.handleChange}
                                                    value={props.values.email}
                                                    className="form-control"
                                                />
                                                {props.errors.email ? <div style={{ color: 'red' }}>{props.errors.email}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group m-2">
                                                <input
                                                    id="city"
                                                    name="city"
                                                    placeholder="Ville"
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    value={props.values.city}
                                                    className="form-control"
                                                />
                                                {props.errors.city ? <div style={{ color: 'red' }}>{props.errors.city}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group m-2">
                                                <select
                                                    id="typeConsult"
                                                    name="typeConsult"
                                                    onChange={props.handleChange}
                                                    value={props.values.typeConsult}
                                                    className="form-control"
                                                >
                                                    <option value="">Objet de la demande</option>
                                                    <option value="1">Prendre rendez-vous en urgence</option>
                                                    <option value="2">Consultation médecine générale</option>
                                                    <option value="3">Consultation pédiatrique</option>
                                                    <option value="4">Etre rappelé(e)</option>
                                                    <option value="5">Autre</option>
                                                </select>
                                                {props.errors.typeConsult ? <div style={{ color: 'red' }}>{props.errors.typeConsult}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group m-2">
                                                <textarea
                                                    id="comment"
                                                    name="comment"
                                                    placeholder="Commentaire..."
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    value={props.values.comment}
                                                    className="form-control"
                                                />
                                                {props.errors.comment ? <div style={{ color: 'red' }}>{props.errors.comment}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="custom-control custom-checkbox  m-2">
                                                <input
                                                    id="newsletter"
                                                    name="newsletter"
                                                    type="checkbox"
                                                    onChange={props.handleChange}
                                                    value={props.values.newsletter}
                                                    className="custom-control-input"
                                                />
                                                <label htmlFor="newsletter" className="custom-control-label">J'accepte de recevoir les actualités de la MSP La Pyramide et souhaite m’inscrire à la Newsletter.</label>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="custom-control custom-checkbox  m-2">
                                                <input
                                                    id="confidentialPolity"
                                                    name="confidentialPolity"
                                                    type="checkbox"
                                                    onChange={props.handleChange}
                                                    value={props.values.confidentialPolity}
                                                    className="custom-control-input"
                                                />
                                                <label htmlFor="confidentialPolity" className="custom-control-label"> J'accepte la <Link to="/policy" className="color-letters-link">Politique de confidentialité</Link> de la MSP La Pyramide.</label>
                                                {props.errors.confidentialPolity ? <div style={{ color: 'red' }}>{props.errors.confidentialPolity}</div> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Button type="submit" className="mt-3 btn-style-form-contact m-2">Envoyer</Button>
                                </form>
                            </Container>
                        )
                    }
                </Formik>
            </div>
        )
    }
}