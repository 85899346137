import React, { Fragment, useEffect, useRef, useState } from 'react'
import './Home.css'
import { Modal } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { useScreenSize } from '../hooks/screen.size';

function VideoModal({ id, title, show, setShow }) {
  const [options, setOptions] = useState({ height: '360', width: '600', controls: 0, rel: 0, disablekb: 1 })
  const screenSize = useScreenSize()
  const videoRef = useRef()

  useEffect(() => {
    if (!screenSize) return
    if (screenSize.width < 600) {
      setOptions({ height: '180', width: '300', controls: 0, rel: 0, disablekb: 1 })
      return
    }
    if (screenSize.width < 900) {
      setOptions({ height: '270', width: '450', controls: 0, rel: 0, disablekb: 1 })
      return
    }
    setOptions({ height: '360', width: '600', controls: 0, rel: 0, disablekb: 1 })
  }, [screenSize]);

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  }


  return (
    <Fragment>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video">
            <div className='video-container'>
              <YouTube
                ref={videoRef}
                videoId={id}
                opts={options}
                onReady={onPlayerReady}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>

  )
}

export default React.forwardRef(VideoModal)