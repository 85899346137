import React, { useRef } from 'react';
import './Home.css';
import { Row, Stack } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"; // Import pagination styles
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import { GrPrevious, GrNext } from 'react-icons/gr'
import VideoThumbnail from './VideoThumbnail';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Carousel() {
    const swiperRef = useRef(null);
    
    const pauseAutoplay = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.autoplay.stop();
        }
    };

    return (
        <section className='video-section'>
            <Row className="text-center">
                <h2 className='mb-5 mt-4 video-text'>Découvrez nos vidéos</h2>
            </Row>
            <Stack direction='horizontal'>
                <div className="swiper-button-prev custom-button">
                    <GrPrevious />
                </div>
                <Swiper
                    ref={swiperRef}
                    className="custom-swiper"
                    spaceBetween={50}
                    slidesPerView={1}
                    onSwiper={(swiper) => console.log(swiper)}
                    pagination={true}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true
                    }}
                    loop={true}
                >
                    <SwiperSlide onClick={pauseAutoplay}>
                        <VideoThumbnail
                            id={'gOljteJ_bfk'}
                            title={"Les avantages de l'exercice libéral en maison de santé pluriprofessionnelle : ils témoignent"}
                            imgPath={'assets/images/v1.png'}
                            pauseSlider={pauseAutoplay}
                        />
                    </SwiperSlide>
                    <SwiperSlide onClick={pauseAutoplay}>
                        <VideoThumbnail
                            id={'ouq048cmTu4'}
                            title={"MSP La Pyramide : une équipe pluridisciplinaire et soudée qui s'agrandit"}
                            imgPath={'assets/images/v2.png'}
                            pauseSlider={pauseAutoplay}
                        />
                    </SwiperSlide>
                    <SwiperSlide onClick={pauseAutoplay}>
                        <VideoThumbnail
                            id={'iL5Z4635zoQ'}
                            title={"La MSP La Pyramide à Athis-Mons : une offre de soins innovante"}
                            imgPath={'assets/images/v3.png'}
                            pauseSlider={pauseAutoplay}
                        />
                    </SwiperSlide>
                    <SwiperSlide onClick={pauseAutoplay}>
                        <VideoThumbnail
                            id={'PM1hq9Xic88'}
                            title={"La Maison de santé pluriprofessionnelle La Pyramide : un projet collectif"}
                            imgPath={'assets/images/v4.png'}
                            pauseSlider={pauseAutoplay}
                        />
                    </SwiperSlide>
                </Swiper>
                <div className="swiper-button-next custom-button">
                    <GrNext />
                </div>
            </Stack>


        </section>
    );
}
