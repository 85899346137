import React, { useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { Formik, Form, Field } from "formik";
import { validateSchema } from './MemberSchema';
import { addMember } from './TeamApi';
import { Fragment } from 'react';
import './Team.css'

export default function AddMember({ specialties, show, setShow }) {
  const [image, setImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageError, setimageError] = useState(false);

  const handleLoadImage = (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      if (Math.round(file.size / 1000) > 1024) {
        setimageError(true)
      } else {
        setimageError(false)
        setImage(file)
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl)
      }
    }
  }

  const closeModal = () => {
    setImageUrl('')
    setImage('')
    setShow(false)
  }

  const submitForm = async (data) => {
    data = { ...data, image: image }
    let response = await addMember(data)
    if (response && response.status === 200) closeModal()
  }

  return (
    <Modal
      show={show}
      size="lg"
      centered
      onHide={closeModal}
      onExit={closeModal}
    >
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          description: '',
          email: '',
          phone_number: '',
          locations: ['AM'],
          specialty: '-1',
          gender: 'W',
          image: '',
          link: '',
          button: '',
          link2: '',
          button2: ''
        }}
        validationSchema={validateSchema}
        onSubmit={async (data) => await submitForm(data)}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <Container>
              <Modal.Header closeButton>
                <Modal.Title>Ajouter un nouvel membre</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Fragment>
                  <Row>
                    <Col md={'4'} aria-rowspan={'4'}>
                      <Row className='align-items-start'>
                        <Col>
                          <Row>
                            <label htmlFor="image" className='mt-4'>
                              <span className="button-input-file-style">Parcourir...</span>
                            </label>
                          </Row>
                          <Col>
                            <Field
                              type='file'
                              name="image"
                              accept='image/jpg,image/jpeg,image/png'
                              onChange={handleLoadImage}
                              id="image"
                              style={{ width: "0px", height: "0px", opacity: 0, overflow: "hidden", position: "relative", zIndex: -1 }}
                            />
                            {imageUrl &&
                              <img className='m-4' src={imageUrl} alt="Avatar" height="180" />
                            }
                            {!imageUrl &&
                              <img className='m-4' src='../assets/images/defImage.png' alt="Avatar" height="180" />
                            }
                            {touched.image && imageError ? (<div className='validation-error'>L'image doit faire au maximum 1 Mo.</div>) : null}
                          </Col>
                        </Col>
                      </Row>
                      <Row className='align-items-end'>
                        <Col>
                          <label className='p-2'>
                            <Col>Civilité *:</Col>
                            <Col className='mt-2 form-check form-check-inline'>
                              <Field className='form-check-input' type="radio" name="gender" value="W" />
                              <label className='form-check-label'>Madame</label>
                            </Col>
                            <Col className='form-check form-check-inline'>
                              <Field className='form-check-input' type="radio" name="gender" value="M" />
                              <label className='form-check-label'>Monsieur</label>
                            </Col>
                            {touched.gender && errors.gender ? (<div className='validation-error'>{errors.gender}</div>) : null}
                          </label>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={'8'}>
                      <Row>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='first_name' placeholder='Nom *' autoComplete="off" />
                          {touched.first_name && errors.first_name ? (<div className='validation-error'>{errors.first_name}</div>) : null}
                        </label>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='last_name' placeholder='Prénom *' autoComplete="off" />
                          {touched.last_name && errors.last_name ? (<div className='validation-error'>{errors.last_name}</div>) : null}
                        </label>
                      </Row>
                      <Row>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='email' placeholder='Email' autoComplete="off" />
                          {touched.email && errors.email ? (<div className='validation-error'>{errors.email}</div>) : null}
                        </label>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='phone_number' placeholder='Téléphone' autoComplete="off" />
                          {touched.phone_number && errors.phone_number ? (<div className='validation-error'>{errors.phone_number}</div>) : null}
                        </label>
                      </Row>
                      <Row className="justify-content-end">
                        <div>
                          <Col>Ville d'exercice *:</Col>
                          <Col className='mt-2 form-check form-check-inline'>
                            <Field id="AM" className='form-check-input' type="checkbox" name="locations" value="AM" />
                            <label htmlFor="AM" className='form-check-label'>Athis-Mons</label>
                          </Col>
                          <Col className='form-check form-check-inline'>
                            <Field id="CE" className='form-check-input' type="checkbox" name="locations" value="CE" />
                            <label htmlFor="CE" className='form-check-label'>Corbeil-Essonnes</label>
                          </Col>
                          {touched.locations && errors.locations ? (<div className='validation-error'>{errors.locations}</div>) : null}
                        </div>
                      </Row>
                      <Row>
                        <label className='col-md-12 p-2'>
                          <Field as='select' name='specialty' className='form-control mb-2' value={values.specialty} >
                            <option value='-1'>Titre ou fonction *:</option>
                            {specialties.map((s, i) => <option key={i} value={s.id.toString()}>{s.wording}</option>)}
                          </Field>
                          {touched.specialty && errors.specialty ? (<div className='validation-error'>{errors.specialty}</div>) : null}
                        </label>
                      </Row>
                      <Row>
                        <label htmlFor="description" className='col-md-12 p-2'>
                          <Field as='textarea' id='description' name='description' className='form-control' maxLength='255' placeholder='Description médecin spécialiste' />
                        </label>
                      </Row>
                      <Row>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='link' placeholder='Lien 1' autoComplete="off" />
                          {touched.link && errors.link ? (<div className='validation-error'>{errors.link}</div>) : null}
                        </label>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='button' placeholder='Texte du lien 1' autoComplete="off" />
                          {touched.button && errors.button ? (<div className='validation-error'>{errors.button}</div>) : null}
                        </label>
                      </Row>
                      <Row>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='link2' placeholder='Lien 2' autoComplete="off" />
                          {touched.link2 && errors.link2 ? (<div className='validation-error'>{errors.link2}</div>) : null}
                        </label>
                        <label className='col-md-6 p-2'>
                          <Field className='form-control mb-2' type="text" name='button2' placeholder='Texte du lien 2' autoComplete="off" />
                          {touched.button2 && errors.button2 ? (<div className='validation-error'>{errors.button2}</div>) : null}
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Fragment>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Annuler</Button>
                <Button type='submit'>Confirmer</Button>
              </Modal.Footer>
            </Container>
          </Form>
        )}

      </Formik>
    </Modal>

  )
}
