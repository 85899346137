
import React, { Component } from 'react'
import HeaderDashboard from '../../header/HeaderDashboard'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { withRouter } from "react-router-dom";
import API from '../../axios'
import User from '../../login/User'
import FooterDashboard from '../../footer/FooterDashboard'



class NewsletterView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urlImage: null,
            title: null,
            content: null,
            video: null,
            newsletter: false
        }
    }

    componentDidMount() {
        const id = this.props.match.params.newsletterId;
        let pathname = this.props.location.pathname
        if (pathname.includes("newsletter")) {
            this.setState({ newsletter: true })
        }
        API.get('post/get/byCode?code=' + id).then((response) => {
            let textTitle = response.data.data.title;
            let title = textTitle.replace(/<p>/gi,'<h2>')
            let text = response.data.data.text
            let textFormat = text.replace(/&nbsp;/gi, '')
            this.setState({ title: title, content: textFormat, urlImage: response.data.data.image_name })
        });
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={2}>
                            <HeaderDashboard active={this.state.newsletter} />
                        </Col>
                        <Col sm={12} md={12} lg={10} className="col-post" >
                            <User />
                            <div className="title-editor" dangerouslySetInnerHTML={{ __html: this.state.title }} />
                            <div align="center">{this.state.urlImage !== null ? <img src={process.env.REACT_APP_S3_HOST + process.env.REACT_APP_IMAGES_POST_PATH + "/" + this.state.urlImage} alt="post" className="img-post-view" /> : <div></div>}</div>
                            <div className="paragraph-editor" dangerouslySetInnerHTML={{ __html: this.state.content }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FooterDashboard />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withRouter(NewsletterView)