import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'


export default class User extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             email:null
        }
    }
    
    componentDidMount() {
        this.setState({email: localStorage.getItem('email')})
    }

    clickLinkLogout(){
        localStorage.removeItem('usuario');
        localStorage.removeItem('email');
        localStorage.removeItem('id');
    }
    render() {
        return (
            <div className="container-user">
                <div className="dropdown ">
                    <a className="dropdown-toggle a-dropdown" href="!#" id="dropdown-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{localStorage.getItem('usuario')}</a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <NavLink className="nav-link dropdown-item" to={"/password/"+this.state.email} activeClassName="current">Changer le mot de passe</NavLink>
                        <NavLink className="nav-link dropdown-item" to="/" activeClassName="current" onClick={this.clickLinkLogout.bind(this)}>Se déconnecter</NavLink>
                    </div>

                </div>
            </div>
        )
    }
}
