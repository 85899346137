import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import HeaderDashboard from '../../header/HeaderDashboard';
import User from '../../login/User'
import FooterDashboard from '../../footer/FooterDashboard'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-balloon'
import ImageBase64 from '../../helpers/ImageBase64';
import { Redirect } from 'react-router-dom';
import { addPost, getPostCategories } from './PostApi';
import ReactPlayer from 'react-player';
import { FaImage } from "react-icons/fa6";

const CreatePost = ({ location }) => {
  const [title, setTitle] = useState("<h1>Titre</h1>");
  const [content, setContent] = useState("<p>&nbsp;</p><p>&nbsp;</p>");
  const [videos, setVideos] = useState(null);
  const [video, setVideo] = useState("");
  const [image, setImage] = useState(null);
  const [imgB64, setImgB64] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [alert, setAlert] = useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [deleteVideo, setDeleteVideo] = useState(false);
  const [dataVideo, setDataVideo] = useState("Lien de la vidéo");
  const [newsletter, setNewsletter] = useState(false);

  useEffect(() => {
    getPostCategories()
      .then((categories) => {
        setCategories(categories.data)
      })
  }, [])

  const fileSelected = (fileInfo) => {
    if (!fileInfo) return;

    if (!fileInfo.size || fileInfo.size > 2048) {
      setImage(null);
      setAlert(true);
    } else {
      setImgB64(fileInfo.base64)
      setImageName(fileInfo.name)
      setImage(fileInfo.file)
      setAlert(false);
    }
  };

  const onchangeCheckbox = (event) => {
    setCheckbox(event.target.checked);
  };

  const responseForRedirect = (res) => {
    if (res.status === 200) {
      setRedirect(true);
    }
  };

  const redirectDashboard = () => {
    if (redirect) {
      sessionStorage.setItem('messageCreate', 'Post publié avec succès');
      return <Redirect to="/dashboard" />;
    }
  };

  const clickTrash = () => {
    setDataVideo('<figure class="media"></figure>');
    setVideo("");
  };

  const onClickSubmit = async () => {
    try {
      let response = await addPost({
        title: title,
        text: content,
        published: checkbox ? "N" : "Y",
        author: parseInt(localStorage.getItem('id')),
        category: selectedCategory,
        video: video,
        image: image,
        imageName: imageName
      });
      responseForRedirect(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const verifyVideoUrl = (event, editor) => {
    let data = editor.getData();
    if (data !== null) {
      setDeleteVideo(true);
    }
    var re = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
    var found = data.match(re);
    setVideo(data);
    if (found !== null) {
      setVideos(
        found
          .filter((item, i) => (item[i] !== item[i + 1]))
          .map((item, i) => <ReactPlayer className="video-editor" key={i} url={item} />)
      );
    } else {
      setVideos(null);
    }
  };

  useEffect(() => {
    if (!location) return

    let pathname = location.pathname;
    if (pathname.includes("newsletter")) {
      setNewsletter(true);
    }
    clickTrash();
  }, [location]);

  let alert_class = alert ? 'alert-visible' : 'alert-hide';

  return (
    <div>
      {redirectDashboard()}
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={2}>
            <HeaderDashboard active={newsletter} />
          </Col>
          <Col sm={12} md={12} lg={10}>
            <User />
            <Container fluid className="container-editor">
              <Row>
                <Col className="editor-padding" sm={12} md={12} lg={5}>
                  <h1>Créer un post</h1>
                  <h5>Titre<span style={{ color: "red" }}> *</span></h5>
                  <CKEditor
                    editor={ClassicEditor}
                    data={title}
                    config={{ toolbar: ['heading'] }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTitle(data);
                    }}
                  />
                  <Alert variant="danger" dismissible className={alert_class}>
                    <Alert.Heading>Oh snap! Tu as une erreur!</Alert.Heading>
                    <p>Donner le format de l'image obligatoire (png ou jpg ou taille max 2MB.)</p>
                  </Alert>
                  <h5>Image<span style={{ color: "red" }}> *</span></h5>
                  <ImageBase64
                    id="file"
                    value="Aucun fichier sélectionné"
                    onDone={fileSelected}
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1
                    }}
                  />
                  <p><i>png ou jpg, taille max 2MB</i></p>
                  <h5>Vidéo</h5>
                  {deleteVideo ? <span className="material-icons btn-delete-database" onClick={clickTrash}>delete</span> : null}
                  <CKEditor
                    data={dataVideo}
                    editor={ClassicEditor}
                    config={{ toolbar: [] }}
                    onChange={verifyVideoUrl}
                  />
                  <h5>Le Contenu<span style={{ color: "red" }}> *</span></h5>
                  <CKEditor
                    data={content}
                    editor={ClassicEditor}
                    config={{ toolbar: ['bold', 'italic', 'link', 'blockQuote', 'bulletedList', 'numberedList'] }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      const cita = data.replace('blockquote', 'blockquote class="cita-editor"');
                      const a = cita.replace('<a', '<a target="_blank"');
                      setContent(a);
                    }}
                  />
                  <div className="container-category-published">
                    <div className="custom-control custom-checkbox mr-sm-2 container-checkbox-published">
                      <input className="custom-control-input" id="customControlAutosizing" type="checkbox" onChange={onchangeCheckbox} />
                      <label className="custom-control-label" htmlFor="customControlAutosizing">Voulez-vous masquer la publication?</label>
                    </div>

                    <select onChange={e => setSelectedCategory(e.target.value)} value={selectedCategory || ''} id="inlineFormCustomSelect" className="custom-select mr-sm-2">
                      <option value="">Type de publication *</option>
                      {categories !== null ? categories.map(category => (
                        <option key={category.id} value={category.id}>{category.wording}</option>
                      )) : <option value="">Type de publication</option>}
                    </select>
                  </div>
                  <div className="container-button-editor">
                    <Button type="button" className="mt-3 btn-style-form-newsletter"
                      onClick={onClickSubmit}
                      disabled={selectedCategory === '' || title === "<h1>Title</h1>" || content === "<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>" || image === null}>
                      Publier
                    </Button>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={5}>
                  <h1>Aperçu</h1>
                  <div className="title-editor-preview" dangerouslySetInnerHTML={{ __html: title }} />
                  <div className="container text-center">
                    {!imgB64 &&
                      <FaImage fontSize={200} />
                    }
                    {imageName && imgB64 &&
                      <img
                        src={imgB64}
                        alt={title}
                        height={200}
                      />
                    }
                  </div>
                  <div className="paragraph-editor-preview" dangerouslySetInnerHTML={{ __html: content }} />
                  <div className="container-video-editor">{videos}</div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <FooterDashboard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreatePost;

