import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormContact from './FormContact'
import  Header  from '../header/Header'
import  Footer  from '../footer/Footer'


export default class Contact extends Component {

    render() {
        return (
            <div>
              <Header/>
              <Container className="container-main-contact">
                <Row >
                  <Col sm={12}>
                    <h1 className="title-contact">Nous Contacter</h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <p className="text-info-contact">Si vous souhaitez obtenir plus d’informations sur la maison de santé pluriprofessionnelle La Pyramide,<br/> laissez-nous votre message !</p>
                  </Col>
                </Row>
                <Row className="container-info">
                  <Col sm={12} lg={6}>
                    <FormContact />
                  </Col>
                  <Col lg={6} className="info-contact">
                    <Row>
                      <Col lg={12}>
                        <p>Maison de santé pluriprofessionnelle La Pyramide <br/> 91200 Athis Mons - France <br/><br/> E-mail: <span className="color-green-contact">secretariat@msp-la-pyramide.com</span><br/>Téléphone: 01 69 48 46 78</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Footer/>
            </div>
        )
    }
}