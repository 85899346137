import React from 'react'
import { Container } from 'react-bootstrap'
import { useScreenSize } from '../hooks/screen.size'

export default function PunchTitle() {
  const screenSize = useScreenSize()

  return (
    <div style={{ padding: screenSize.width > 900 ? 40 : 20 }}>
      <Container>
        <p
          className='punch-title'
          style={{ fontSize: screenSize.width > 900 ? 40 : 20 }}
        >
          Toute l’équipe médicale de la Maison de santé pluriprofessionnelle La Pyramide vous souhaite la bienvenue !
        </p>
      </Container>
    </div>

  )
}
