import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Maps from './Maps';

export default function GeneralInfo() {
    return (
        <Container fluid className="container-info-home">
            <Row>
                <Col sm={12} md={6} className="container-info-practice">
                    <h2 className="subtitle-header"><b>Informations pratiques</b></h2>
                    <p className="item-info-blue">
                        <span className="material-icons material-icons-info align-middle">local_phone</span>
                        <b>    Secrétariat:&nbsp;&nbsp;<a className="color-blue color-green-hover" href="tel:0169484678">01 69 48 46 78</a></b>
                    </p>
                    <p className="item-info-blue">
                        <span className="material-icons material-icons-info align-middle">location_on</span>
                        <b>    Adresse :&nbsp;&nbsp;2 Route de Montlhéry, 91200 Athis-Mons</b>
                    </p>
                    <p className="item-info-blue">
                        <span className="material-icons material-icons-info align-middle">mail_outline</span>
                        <b>    E-mail :&nbsp;&nbsp;<a className="color-blue color-green-hover" href="mailto:secretariat@msp-la-pyramide.com">secretariat@msp-la-pyramide.com</a></b>
                    </p>
                    <p className="item-info-blue">
                        <span className="material-icons material-icons-info align-middle">cloud_queue</span>
                        <b>    Site web :&nbsp;&nbsp;<a className="color-blue color-green-hover" rel="noopener noreferrer" href="https://msp-la-pyramide.com" target='_blank'>www.msp-la-pyramide.com</a></b>
                    </p>
                    <h2 className="subtitle-header"><b>Pour se garer</b></h2>
                    <p className="item-info-blue"><b>
                        <a className="color-blue color-green-hover" rel="noopener noreferrer" href="https://goo.gl/maps/aRpfyNWc7Q19BYsJA" target="_blank">Parking, 19 Av. François Mitterrand, 91200 Athis-Mons</a></b>
                    </p>
                    <h2 className="subtitle-header"><b>Transports à proximité</b></h2>
                    <div className="container-ligne-bus">
                        <b>    Bus: arrêt Pyramide de Juvisy, ligne  </b>
                        <Container className="routes-bus">
                            <Row>
                                <Col>
                                    <span className="color-purple">285</span>
                                    <span className="color-orange">385</span>
                                    <span className="color-blue-light">399</span>
                                    <span className="color-white">487c</span>
                                    <span className="color-white">N31</span>
                                    <span className="color-white">N131</span>
                                    <span className="color-blue-dark">N144</span>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="routes-bus-sm">
                            <Row style={{ marginBottom: "12px" }}>
                                <Col>
                                    <span className="color-purple">285</span>
                                </Col>
                                <Col>
                                    <span className="color-orange">385</span>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "12px" }}>
                                <Col>
                                    <span className="color-blue-light">399</span>
                                </Col>
                                <Col>
                                    <span className="color-white">487c</span>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "12px" }}>
                                <Col>
                                    <span className="color-white">N31</span>
                                </Col>
                                <Col>
                                    <span className="color-white">N131</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="color-blue-dark">N144</span>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <p className="container-ligne-bus">
                        <b>    RER : arrêt Juvisy, ligne  </b>
                        <span className="circle-yellow"><b>C</b></span>
                        <span className="circle-green"><b>D</b></span>

                    </p>
                </Col>
                <Col sm={12} md={6} className="center-map">
                    <Maps />
                </Col>
            </Row>
        </Container>
    )
}
