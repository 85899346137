import React from 'react'
import './subheader.css';

export default function Subheader() {
    return (
        <div className="subheader">
            <div className="slider">
                <div className="slide-track">
                    <div className="slide">
                        <span>Le point de fixe de garde (PFG) est ouvert du lundi au vendredi de 20h à minuit.</span>
                    </div>
                    <div className="slide">
                        <span>Pour obtenir un rendez-vous de consultation d'urgence, merci d'appeler le secrétariat au 01 69 48 46 78 ou le SAMU Centre 15 à partir de 17h.</span>
                    </div>
                    <div className="slide">
                        <span>Besoin d'une infirmière ? Notre équipe assure des soins infirmiers à la MSP et à domicile pour plus d'informations, veuillez contacter le secrétariat.</span>
                    </div>
                    <div className="slide">
                        <span>Le point de fixe de garde (PFG) est ouvert du lundi au vendredi de 20h à minuit.</span>
                    </div>
                    <div className="slide">
                        <span>Pour obtenir un rendez-vous de consultation d'urgence, merci d'appeler le secrétariat au 01 69 48 46 78 ou le SAMU Centre 15 à partir de 17h.</span>
                    </div>
                    <div className="slide">
                        <span>Besoin d'une infirmière ? Notre équipe assure des soins infirmiers à la MSP et à domicile pour plus d'informations, veuillez contacter le secrétariat.</span>
                    </div>
                </div>
            </div>
        </div>
    )

}