import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CookieBanner from './cookies/CookieBanner'
import Home from './home/Home'
import Team from './team/Team'
import Post from './posts/Post'
import Posts from './posts/Posts'
import Contact from './contact/Contact'
import Policy from './policy/Policy'
import Legaly from './legaly/Legaly'
import NewsletterView from './dashboard/newsletter/NewsletterView'
import EditPost from './dashboard/newsletter/EditPost'
import CreatePost from './dashboard/newsletter/CreatePost'
import ContactDashboard from './dashboard/contact/Contact'
import MemberList from './dashboard/team/MemberList'
import PasswordDashboard from './dashboard/password/PasswordDashboard'
import Login from './login/Login'
import Password from './login/Password'
import PageNotFound from './error/PageNotFound'
import { PrivateRoute } from './helpers/PrivateRoute'
import VideoList from './dashboard/videos/VideoList';
import ContactList from './dashboard/contact/ContactList';
import PostList from './dashboard/newsletter/PostList';
import ImageGallery from './gallery/ImageGallery';

export default class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/team" component={Team} />
              <Route exact path="/posts" component={Posts} />
              <Route exact path="/gallery" component={ImageGallery} />
              <Route exact path="/post/:idPost" component={Post} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/policy" component={Policy} />
              <Route exact path="/legaly" component={Legaly} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/password" component={Password} />
              <PrivateRoute exact path="/dashboard" component={PostList} />
              <PrivateRoute exact path="/newsletter/create" component={CreatePost} />
              <PrivateRoute exact path="/newsletterView/:newsletterId" component={NewsletterView} />
              <PrivateRoute exact path="/newsletterEdit/:newsletterId" component={EditPost} />
              <PrivateRoute exact path="/dashboard/contact" component={ContactDashboard} />
              <PrivateRoute exact path="/dashboard/team" component={MemberList} />
              <PrivateRoute exact path="/dashboard/videos" component={VideoList} />
              <PrivateRoute exact path="/dashboard/contlist" component={ContactList} />
              <PrivateRoute exact path="/password/:email" component={PasswordDashboard} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </Router>
        <CookieBanner/>
      </div>
    )
  }
}

