import React, { Component } from 'react'
import HeaderDashboard from '../../header/HeaderDashboard'
import User from '../../login/User'
import DataTables from '../datatable/DataTables'
import API from '../../axios';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FooterDashboard from '../../footer/FooterDashboard'

const columns = [
    {
        "key": 1,
        "name": "Date",
        "width": "60px"
    },
    {
        "key": 2,
        "name": "Nom",
        "width": "60px"
    },
    {
        "key": 3,
        "name": "Email",
        "width": "60px"
    },
    {
        "key": 4,
        "name": "Téléphone",
        "width": "50px"
    },
    {
        "key": 5,
        "name": "Ville",
        "width": "50px"
    },
    {
        "key": 6,
        "name": "Message",
        "width": "10px"
    }
];

const columnFieldCheckbox = "Abonné(e) à la Newsletter";

const action = false;

const btn_create = false;

export default class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            fieldCheckbox: []
        }
    }
    componentDidMount() {
        API.get('user/get/contacts').then((response) => {
            const dataPost = [];
            const fieldCheckbox = [];
            const data = response.data.data;
            data.map((register) => {
                let current_datetime = new Date(register.recording)
                let month = (current_datetime.getMonth() + 1)
                let formatted_date = current_datetime.getFullYear() + "-" + month + "-" + current_datetime.getDate()
                if (register.subscribed === "N") {
                    fieldCheckbox.push({ id: register.id, checked: false })
                } else {
                    fieldCheckbox.push({ id: register.id, checked: true })
                }
                return dataPost.push({
                    id: register.id,
                    date:formatted_date,
                    name: register.first_name + ' ' + register.last_name,
                    email: register.email,
                    phone_number: register.phone_number,
                    city: register.city,
                    messages: register.comment
                })
            })
            this.setState({ data: dataPost, fieldCheckbox: fieldCheckbox })
        });
    }
    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={2}>
                            <HeaderDashboard />
                        </Col>
                        <Col sm={12} md={12} lg={10}>
                            <User />
                            <DataTables columns={columns} rows={this.state.data} fieldChecbox={this.state.fieldCheckbox} nameFieldCheckbox={columnFieldCheckbox} action={action} btn_create={btn_create}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FooterDashboard/>
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    }
}
