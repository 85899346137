import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from "formik";
import { deleteMember } from './TeamApi';

export default function DeleteMember(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show)
    }, [props]);

    const closeModal = () => {
        setShow(false)
        props.closeFromChild()
    }

    const submitForm = async (data) => {
        let response = await deleteMember(data)
        if (response && response.status === 200) closeModal()
    }

    return (

        <Modal
            show={show}
            size="md"
            centered
            onHide={closeModal}
            onExit={closeModal}
        >
            <Formik
                initialValues={{id: props.member.id, image_name: props.member.image_name}}
                onSubmit={async (data) => await submitForm(data)}
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Supprimer le membre</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Voulez-vous vraiment supprimer ce membre ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Annuler</Button>
                        <Button type='submit'>Confirmer</Button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>

    )
}
