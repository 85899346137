import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import API from '../axios';
import PostGrid from './PostGrid'
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import './Posts.css'

export default class Posts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hideEvent: true,
            hideWordSearch: true,
            imgChangeEvent: '../assets/images/search.png',
            evenements: null,
            infosante: null,
            liensutiles: null,
            code: 0
        }
        this.resetInput = React.createRef();

    }

    onChangeSearch() {
        API.get('post/get/published?keywords=%' + this.resetInput.current.value + '%').then((response) => {
            this.setState({ evenements: response.data.data.evenements, infosante: response.data.data.infosante, liensutiles: response.data.data.liensutiles })
        });
    }

    clickEvents() {
        this.setState({
            hideEvent: !this.state.hideEvent,
            hideWordSearch: !this.state.hideWordSearch,
            imgChangeEvent: !this.state.imgChangeEvent
        })
        this.resetInput.current.value = ""
        API.get('post/get/published?keywords=%' + this.resetInput.current.value + '%').then((response) => {
            this.setState({ evenements: response.data.data.evenements, infosante: response.data.data.infosante, liensutiles: response.data.data.liensutiles })
        });
    }

    componentDidMount() {
        API.get('post/get/published').then((response) => {
            this.setState({ evenements: response.data.data.evenements, infosante: response.data.data.infosante, liensutiles: response.data.data.liensutiles })
        });
    }

    render() {
        let btn_class_event = this.state.hideEvent ? "hiddenInput" : "squared-input full-width visibleInput";
        let btn_class_word_search = this.state.hideWordSearch ? "visibleInput search" : "hiddenInput";
        let event = this.state.imgChangeEvent ? "../assets/images/search.png" : "../assets/images/close.png";
        
        return (

            <div>
                <Header />
                <Row className='m-4'>
                    <Col md={8} sm={8} className="text-end"></Col>
                    <Col md={4} sm={4} className="d-flex text-end">
                        <Form.Control type="text" placeholder="Recherche" ref={this.resetInput} className={btn_class_event} onChange={this.onChangeSearch.bind(this)} fluid/>
                        <a href="#!" onClick={this.clickEvents.bind(this)} className='m-2' fluid>
                            <img src={event} alt="Recherche" width="32" />
                            <span className={btn_class_word_search}>Lancer une recherche</span>
                        </a>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="evenements" id="posts" className="mt-2 mb-2" justify>
                    <Tab eventKey="evenements" title="Evénements">
                        <Container className="container-updates">
                            <PostGrid posts={this.state.evenements} />
                        </Container>
                    </Tab>
                    <Tab eventKey="liensutiles" title="Info Santé">
                        <Container className="container-updates">
                            <PostGrid posts={this.state.infosante} />
                        </Container>
                    </Tab>
                    <Tab eventKey="infosante" title="Liens utiles">
                        <Container className="container-updates">
                            <PostGrid posts={this.state.liensutiles} />
                        </Container>
                    </Tab>
                </Tabs>
                <Footer />
            </div>
        )
    }
}


