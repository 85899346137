import React from 'react'
import CookieConsent from "react-cookie-consent";

export default function CookieBanner() {
  return (
    <CookieConsent
      enableDeclineButton
      flipButtons
      location="bottom"
      buttonText="OK, tout accepter"
      declineButtonText='Tout refuser'
      cookieName="user-preferences"
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: '#27A746', color: 'white', fontSize: "14px" }}
      declineButtonStyle={{ background: '#DC3545', color: "white", fontSize: "14px" }}
      expires={160}
    >
      <span style={{ fontSize: "12px" }}>
        Nous utilisons des cookies pour améliorer la performance de notre site, vous permettre de partager des
        contenus ou mieux comprendre comment
        vous interagissez avec notre site. En acceptant, vous consentez à notre utilisation de ces cookies.{" "}
        <a href="/policy">Politique de confidentialité</a>
      </span>
    </CookieConsent>
  )
}
