import API from '../../axios';

export const getPostList = async () => {
    let response = await API.get('/post/get/all')
    return response?.data?.data.map((post) => {
        let current_datetime = new Date(post.last_edition)
        let month = (current_datetime.getMonth() + 1)
        let formatted_date = current_datetime.getFullYear() + "-" + month + "-" + current_datetime.getDate()

        return {
            id: post.id,
            code: post.code,
            date: formatted_date,
            title: post.title.replace(/<[^>]*>?/g, ''),
            text: post.text,
            views: post.views,
            category: post.category_wording,
            published: post.published,
            image_name: post.image_name,
            notified: post.notified
        }
    })
}

export const getPost = async (postId) => {
    const {data} = await API({
        url: 'post/get/byCode?code=' + postId
    })

    return {
        code: data.data.code,
        title: data.data.title,
        content: data.data.text,
        dataVideo: data.data.video !== null && data.data.video !== "" ? data.data.video : "Lien de la vidéo",
        imageName: data.data.image_name,
        category: data.data.category,
        selectedCategory: data.data.category
    }
};

export const addPost = async (post) => {
    const formData = new FormData();
    
    formData.append('title', post.title)
    formData.append('text', post.text)
    formData.append('published', post.published)
    formData.append('author', post.author)
    formData.append('category', post.category)
    formData.append('video', post.video)
    if(post.imageName) formData.append('imgFormat', post.imageName.split('.').pop())
    if(post.image) formData.append('image', post.image)

    return await API({
        method: 'post',
        url: 'post/add/post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const editPost = async (post) => {
    const formData = new FormData();

    formData.append('code', post.code)
    formData.append('title', post.title)
    formData.append('text', post.text)
    formData.append('published', post.published)
    formData.append('author', post.author)
    formData.append('category', post.category)
    formData.append('video', post.video)
    formData.append('imageName', post.imageName)
    if(post.imageName) formData.append('imgFormat', post.imageName.split('.').pop())
    if(post.image != null && post.image !== undefined) formData.append('image', post.image)

    return await API({
        method: 'put',
        url: 'post/edit/post?code=' + post.code,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

export const notifyPost = async (post) => {
    return await API({
        method: 'post',
        url: "post/notify",
        headers: { 'Content-Type': 'application/json' },
        data: post
    })
}

export const deletePost = async (post) => {
    return await API({
        method: 'delete',
        url: "post/remove?code=" + post.code,
        data: {
            code: post.code,
            imageName: post.image_name
        }
    })
}

export const getPostCategories = async () => {
    const { data } = await API({
        method: 'get',
        url: '/post/get/categories'
    })
    return data
}