import React, { useEffect, useState } from 'react'
import HeaderDashboard from '../../header/HeaderDashboard'
import User from '../../login/User'
import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FooterDashboard from '../../footer/FooterDashboard'
import { getPostList } from './PostApi'
import ReactPaginate from 'react-paginate'
import DeletePost from './DeletePost'
import NotifyPost from './NotifyPost'

export default function PostList() {
    const [postList, setPostList] = useState([]);
    const [currentPost, setCurrentPost] = useState({});
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getPostList().then((data) => setPostList(data))
    }, [])

    useEffect(() => {
        if(!showNotificationModal) getPostList().then((data) => setPostList(data))
        if(!showDeletionModal) getPostList().then((data) => setPostList(data))
    }, [showNotificationModal, showDeletionModal])

    const handlePageClick = (e) => {
        setOffset(e.selected * 15)
    }

    const handleValuesToRemove = (postId) => {
        setCurrentPost(postList.find((post) => post.id === postId))
        setShowDeletionModal(true)
    }
    
    const handleValuesToNotify = (postId) => {
        setCurrentPost(postList.find((post) => post.id === postId))
        setShowNotificationModal(true)
    }

    const getNotificationIcon = (post) => {
        if (post.published === 'Y') {
            if (post.notified === 'Y') return <span className='clickable action-icon'><i className="material-icons size-icono disabled-icon">mark_email_read</i></span>
            else return <span className='clickable action-icon' onClick={() => handleValuesToNotify(post.id)}><i className="material-icons size-icono">email</i></span>
        } else {
            return <span className='clickable action-icon'><i className="material-icons size-icono disabled-icon">email</i></span>
        }
    }

    return (
        <div style={{ margin: '0' }}>
            <Container fluid>
                <Row>
                    <Col sm={12} md={12} lg={2}>
                        <HeaderDashboard />
                    </Col>
                    <Col sm={12} md={12} lg={10}>
                        <User />
                        <div className="container-datatable">
                            <div className="container-table">
                                <div className='container-btn-create-post'>
                                    <NavLink to='/newsletter/create' className="btn-create-post"><img src="../../assets/images/add.png" alt="icon add" /></NavLink>
                                </div>
                                <table className='full-width'>
                                    <thead>
                                        <tr className="container-column-sm">
                                            <th className="column-datatable col-10" key={1}>Date</th>
                                            <th className="column-datatable col-10" key={2}>Titre</th>
                                            <th className="column-datatable col-10" key={3}>Vues</th>
                                            <th className="column-datatable col-10" key={5}>Catégorie</th>
                                            <th className="column-datatable col-20" key={6}>Publié</th>
                                            <th className="column-datatable col-15" key={7}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {postList.slice(offset, offset + 15).map((post, index) => {
                                            return (
                                                <tr className="row-datatable" key={index}>
                                                    <td>{post.date}</td>
                                                    <td>{post.title}</td>
                                                    <td>{post.views}</td>
                                                    <td>{post.category}</td>
                                                    <td>{(post.published === 'Y') ? 'Oui' : 'Non'}</td>
                                                    <td>
                                                        <NavLink className="btn-view-database" to={'/newsletterView/' + post.code}><i className="material-icons size-icono">remove_red_eye</i></NavLink>
                                                        <NavLink className="btn-edit-database" to={'/newsletterEdit/' + post.code}><i className="material-icons size-icono">edit</i></NavLink>
                                                        {getNotificationIcon(post)}
                                                        <span className='clickable action-icon' onClick={() => handleValuesToRemove(post.id)}><i className="material-icons size-icono">delete</i></span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="container-pagination">
                                <ReactPaginate
                                    previousLabel={"Précédent"}
                                    nextLabel={"Suivant"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil(postList.length / 15)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick.bind(this)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FooterDashboard />
                    </Col>
                </Row>
            </Container>

            <NotifyPost show={showNotificationModal} post={currentPost} closeFromChild={() => setShowNotificationModal(false)}></NotifyPost>
            <DeletePost show={showDeletionModal} post={currentPost} closeFromChild={() => setShowDeletionModal(false)}></DeletePost>
        </div>
    )
}
