import React from 'react';
import API from '../axios';
import { Card, Col, Row } from 'react-bootstrap';

export default function PostGrid(props) {
    const clicView = (code) => {
        API({
            method: 'put', url: 'post/add/view?code=' + code, data: {
                code: code
            },
            headers: { 'Content-Type': 'application/json' }
        })
    }

    return (
        <Row className="color-blue section-event-cards">
            {props.posts !== null
                ? props.posts.map((post) => {
                    let textTitle = post.title;
                    let title = textTitle.replace(/(<([^>]+)>)/ig, '<h3>')
                    let styleImagen = { height: "300px" };
                    let text = post.text;
                    let firstTagP = text.substring(0, text.indexOf("</p>"));
                    let textFormatTagPStart = firstTagP.replace(/(<([^>]+)>)/ig, "")
                    let textFormat = textFormatTagPStart.replace(/&nbsp;/gi, '')
                    let content = textFormat.length < 150 ? textFormat : textFormat.substring(0, 150) + "...";
                    let current_datetime = new Date(post.creation)
                    let month = (current_datetime.getMonth() + 1)
                    let formatted_date = current_datetime.getFullYear() + "-" + month + "-" + current_datetime.getDate()
                    let re = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi
                    let findVideo;
                    if (post.video !== null && post.video !== "" && post.video !== "<p>Lien de la vidéo</p>") {
                        findVideo = post.video.match(re);
                    }
                    if (post.published !== "N") {
                        return (
                            <Col key={post.code} sm={12} md={6} lg={4}>
                                <Card className="container-card-post">
                                    <Card.Body>
                                        <div className="title-card-post">
                                            <a rel="noopener noreferrer" href={"/post/" + post.code} target="_blank" className="link-title-card" onClick={() => clicView(post.code)}><Card.Title dangerouslySetInnerHTML={{ __html: title }} /></a>
                                            <Card.Subtitle className="mb-2 text-muted">{formatted_date}</Card.Subtitle>
                                        </div>
                                    </Card.Body>
                                    <a rel="noopener noreferrer" href={"/post/" + post.code} target="_blank" variant="primary" onClick={() => clicView(post.code)} className="container-img-post" style={styleImagen}><Card.Img className="img-post" style={styleImagen} src={process.env.REACT_APP_S3_HOST + process.env.REACT_APP_IMAGES_POST_PATH + "/" + post.image_name} /></a>
                                    <div className="text-card-post"><div dangerouslySetInnerHTML={{ __html: content }} /></div>
                                    <Card.Body >
                                        {post.video !== null && post.video !== "" && post.video !== "<p>Lien de la vidéo</p>" ? <a className="link-card" rel="noopener noreferrer" href={'https://www.' + findVideo[0]} target="_blank">Lien Vidéo</a> : <div></div>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    } else {
                        return (<p>...</p>)
                    }
                })
                : <div></div>}
        </Row>

    );
}
