import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import { Formik } from 'formik'
import * as Yup from 'yup';
import API from '../axios';
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { Redirect } from 'react-router-dom'

const validateSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email invalide')
        .required('Requis')
});

export default class Password extends Component {
    constructor(props) {
        super(props)
        this.alertError = React.createRef();
        this.state = {
            redirect:false
        }
        this.redirectLogin = this.redirectLogin.bind(this)
    }

    componentDidMount() {
        this.alertError.current.style.display = 'none';
    }

    responseForRedirect(res){
        if(res.status === 200){ 
            this.setState({redirect:true})
            
        }
    }
    redirectLogin(){
        if (this.state.redirect) {
            return <Redirect to='/login' />
        }
    }
    render() {
        return (
            <div>
                {this.redirectLogin()}
                <Container fluid className="background-container-login">
                    <Row>
                        <Col>
                        <div className="site-logo" style={{marginBottom:'30px'}}><Link to="/"><img className="logo-icon" src={'../../assets/images/logo-blue.png'} alt="logo" width="200" /></Link></div>
                        </Col>
                    </Row>
                    <Alert variant="danger" ref={this.alertError}>
                        Un ou plusieurs détails de compte sont incorrects
                    </Alert>
                    <div className="title-login" >Merci de renseigner vos informations</div>
                    <Formik
                        initialValues={{
                            email:''
                        }}
                        validationSchema={validateSchema}
                        onSubmit={(values, { resetForm }) => {
                            API({
                                method: 'put', url: '/team/recover/password', data: {
                                    email: values.email
                                },
                                headers: { 'Content-Type': 'application/json' }
                            }).then(res => {
                                sessionStorage.setItem('messageSuccess','Nous avons envoyé un email avec votre nouveau mot de passe')
                                this.responseForRedirect(res)

                            }).catch( (error) => {
                                resetForm();
                                this.alertError.current.style.display = 'block';
                            });
                        }}
                    >
                        {props => (
                            
                            
                            <form onSubmit={props.handleSubmit} className="container-login">
                                <Row className="col-size-input-login">
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="email">Renseignez votre adresse email</label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                onChange={props.handleChange}
                                            />
                                            {props.errors.email ? <div style={{ color: 'red' }}>{props.errors.email}</div> : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row >                                    
                                    <Col className="align-center-button">
                                        <Button type="submit" className="mt-3 btn-style-form"><span className="material-icons">keyboard_arrow_right</span><i>Suivant</i></Button>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                </Container>
            </div>
        )
    }
}
