import React, { Component } from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Conditions extends Component {
    render() {
        return (
            <div>
                <Header/>
                    <Container className="container-legaly">
                        <Row>
                            <Col>
                                <h1>MENTIONS LÉGALES DU SITE</h1>
                                <h5>www.msp-la-pyramide.com</h5>
                                <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs du site www.msp-la-pyramide.com les présentes mentions légales.</p>
                                <p>Le site <span className="color-blue"><b>www.msp-la-pyramide.com</b></span> est accessible à l'adresse suivante:<span className="color-blue"><b> www.msp-la-pyramide.com</b></span> (ci-après "le Site"). L'accès et l'utilisation du Site sont soumis aux présentes " Mentions légales" détaillées ci-après ainsi qu'aux lois et/ou règlements applicables.</p>
                                <p>La connexion, l'utilisation et l'accès à ce Site impliquent l'acceptation intégrale et sans réserve de l'internaute de toutes les dispositions des présentes Mentions Légales.</p>
                                <h5><b>ARTICLE 1:</b> PRÉAMBULE</h5>
                                <p>En vertu de l'Article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé dans cet article l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
                                <h6 className="padding-botton-title color-blue"><b className="text-indent">A.</b> Editeur du site</h6>
                                <p>Le développement informatique du site <span className="color-blue"><b>www.msp-la-pyramide.com</b></span>, son suivi graphique et technique est assuré par:</p>
                                <h6><b>KAIROS ID SAS</b></h6>
                                <p>domicilié à l'adresse suivante : 33, av. du Maine 75015 Paris, et immatriculé au numéro suivant : RCS Paris 83238777300027.</p>
                                <p>Adresse e-mail: <span className="color-blue"><b>contact@kairos-id.com</b></span></p>
                                <p><i>ci-après " l'Éditeur "</i></p>
                                <h6 className="padding-botton-title color-blue"><b className="text-indent">B.</b> Directeur de publication</h6>
                                <p>Le Directeur de publication est: <span className="color-blue"><b>Dr SLIMANI FAROUK</b></span> </p>
                                <p>domicilié à l'adresse suivante: 2 route de Montlhery, 91200 Athis-Mons, établissement immatriculé au numéro suivant : 882 941 891 00013.</p>
                                <p>Adresse e-mail de contact: <span className="color-blue"><b>secretariat@msp-la-pyramide.com</b></span></p>
                                <p><i>ci-après " le Directeur de publication"</i></p>
                                <h6 className="padding-botton-title color-blue"><b className="text-indent">C.</b> Hébergeur du site</h6>
                                <p>Le site <span className="color-blue"><b>www.msp-la-pyramide.com</b></span> est hébergé par:</p>
                                <p><span className="color-blue"><b>OVH SAS</b></span> au capital de 10 069 020 € dont le siège social est situé 2 rue Kellermann - 59100 Roubaix - France et immatriculée sous le numéro RCS Lille Métropole 424 761 419 00045</p>
                                <p><i>ci-après " l'Hébergeur "</i></p>
                                <h6 className="padding-botton-title color-blue"><b className="text-indent">D.</b> Utilisateurs</h6>
                                <p>Sont considérés comme utilisateurs tous les internautes qui naviguent, lisent, visionnent et utilisent le site <span className="color-blue"><b>www.msp-la-pyramide.com.</b></span></p>
                                <p><i>ci-après les " Utilisateurs "</i></p>
                                <h5><b>ARTICLE 2:</b> ACCESSIBILITÉ</h5>
                                <p>Le Site est par principe accessible aux Utilisateurs 24/24h et 7/7j, sauf interruption, programmée ou non, pour des besoins de maintenance ou en cas de force majeure.</p>
                                <p>En cas d'impossibilité d'accès au Site, celui-ci s'engage à faire son maximum afin d'en rétablir l'accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle qu'en soit la nature, résultant de son indisponibilité.</p>
                                <h5><b>ARTICLE 3:</b> LOI APPLICABLE ET JURIDICTION</h5>
                                <p>Les présentes Mentions Légales sont régies par la loi française. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.</p>
                                <h5><b>ARTICLE 4:</b> CONTACT</h5>
                                <p>Pour tout signalement de contenus ou d'activités illicites, l'Utilisateur peut contacter le directeur de publication, Dr SLIMANI FAROUK, à l'adresse électronique suivante: <span className="color-blue"><b>secretariat@msp-la-pyramide.com</b></span>, ou par courrier recommandé avec accusé de réception adressé à: Dr SLIMANI FAROUK, 2 route de Montlhery, 91200 Athis-Mons.</p>
                                <p><i>Le site www.msp-la-pyramide.com vous souhaite une excellente navigation !</i></p>
                            </Col>
                        </Row>
                    </Container>
                <Footer/>
            </div>
        )
    }
}
