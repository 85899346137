import React, { useState } from 'react';

const ImageBase64 = ({ id, style, value, onDone }) => {
  const [imageName, setImageName] = useState('')
  
  const handleChange = (e) => {
    let files = e.target.files;

    if (!files || !files.length) return

    let file = files[0];

    let reader = new FileReader()
    reader.readAsDataURL(file)
    
    reader.onload = () => {
      setImageName(file.name)

      onDone({
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000),
        base64: reader.result,
        file: file,
      })
    };
  };

  return (
    <>
      <input
        id={id}
        name={id}
        type="file"
        onChange={handleChange}
        multiple={false}
        style={style}
        accept="image/jpeg,image/png"
      />
      <label htmlFor={id}>
        <span className="button-input-file-style">Parcourir...</span>
        <span className="text-file-style">
          {imageName !== null ? imageName : value}
        </span>
      </label>
    </>
  );
};

export default ImageBase64;
