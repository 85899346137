import React, { Component } from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { withRouter } from "react-router-dom";
import API from '../axios'

class Post extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urlImage: null,
            title: null,
            content: null,
            video: ""
        }
    }
    componentDidMount() {
        const id = this.props.match.params.idPost;
        API.get('post/get/byCode?code=' + id).then((response) => {
            let textTitle = response.data.data.title;
            let title = textTitle.replace(/<p>/gi, '<h2>')
            let text = response.data.data.text
            let textFormat = text.replace(/&nbsp;/gi, '')
            this.setState({ title: title, content: textFormat, urlImage: response.data.data.image_name })

        });
    }
    render() {
        return (
            <div>
                <Header />
                <Container className="container-post">
                    <Row>
                        <Col className="col-post">
                            <div className="title-post" dangerouslySetInnerHTML={{ __html: this.state.title }} />
                            <div align="center">{this.state.urlImage !== null ? <img src={process.env.REACT_APP_S3_HOST + process.env.REACT_APP_IMAGES_POST_PATH + "/" + this.state.urlImage} alt="post" className="img-post-view" /> : <div></div>}</div>
                            <div className="paragraph-editor " dangerouslySetInnerHTML={{ __html: this.state.content }} />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default withRouter(Post);
