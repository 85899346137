import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Header.css'

export default class HeaderDashboard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            hideNabvar: true,
            hideImageMenu: true,
            hideChangeImg: true,
            email:null
        }
    }

    clickButtonMenu() {
        this.setState({
            hideNabvar: !this.state.hideNabvar,
            hideImageMenu: !this.state.hideImageMenu,
            hideChangeImg: !this.state.hideChangeImg
        })
    }

    clickLinkLogout(){
        localStorage.removeItem('usuario');
    }
    
    componentDidMount() {
        this.setState({ email: localStorage.getItem('email') });
    }

    render() {
        let btn_class_navbar = this.state.hideNabvar ? "container-nav" : "container-nav-sm";
        let btn_class_img = this.state.hideImageMenu ? "visible-img-menu" : "hiden-img-menu";
        let btn_class_span = this.state.hideImageMenu ? "hiden-span-menu" : "visible-span-menu";
        let btn_class_active_newsletter = this.props.active ? "nav-link current" : "nav-link no-current";
        return (
            <div>
                <a href="#!" className="btn-navbar-sm" type="button" onClick={this.clickButtonMenu.bind(this)}>
                    <span className={btn_class_span}><i className="material-icons close-menu">X</i></span>
                    <img src="../../assets/images/menu.png" alt="icono menu" className={btn_class_img}/>
                </a>
                <nav className={btn_class_navbar}>
                    <div className="site-logo-header-dashboard"><Link id="icon-logo" className="scrollto" to="/dashboard"><img className="logo-icon" src={'../../assets/images/logo-blue.png'} alt="logo" width="200" /></Link></div>
                    <NavLink className={btn_class_active_newsletter} to="/dashboard" exact activeClassName="current">Gestion des Actualités</NavLink>
                    <NavLink className="nav-link" to="/dashboard/contlist" activeClassName="current">Gestion des Abonnés</NavLink>
                    <NavLink className="nav-link" to="/dashboard/team" activeClassName="current">Gestion de L’équipe</NavLink>
                    <NavLink className="nav-link" to="/dashboard/videos" activeClassName="current">Gestion de la Vidéo</NavLink>
                    <NavLink className="nav-link logout-sm-menu" to={"/password/"+this.state.email} activeClassName="current">Changer le mot de passe</NavLink>
                    <NavLink className="nav-link logout-sm-menu" to="/" activeClassName="current" onClick={this.clickLinkLogout.bind(this)}>Se déconnecter</NavLink>
                </nav>
            </div>
        )
    }
}
