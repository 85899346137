export const photos = [
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/couloir-msp-2.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/couloir-msp.jpg",
    width: 1,
    height: 2
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/devanture.jpg",
    width: 3,
    height: 1
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/Ecran-attente.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/Entre%CC%81e-msp.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/Laissez-nous+votre+avis.jpg",
    width: 1,
    height: 1
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/MSP-devanture-3.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/MSP-devanture-4.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/MSP-entre%CC%81e-2.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://pyramide-storage-prod.s3.eu-west-3.amazonaws.com/assets/images/gallery/MSP-entre%CC%81e.jpg",
    width: 4,
    height: 3
  }
];
