import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Footer extends Component {
    render() {
        return (
            <footer className="container-fluid py-5 color-letter-footer footer-color-background">
                <Container>
                    <Row>
                        <Col sm={12} md={4}>
                            <div id="logo-footer"><img className="logo-icon m-4" src="../assets/images/logo-blue.png" alt="logo" width="170" /></div>
                        </Col>
                        <Col sm={6} md={4}>
                            <h6>NOUS CONTACTER</h6>
                            <div className="linea-divisora-footer"></div>
                            <ul className="list-unstyled text-small">
                                <li>Maison de santé pluriprofessionnelle La Pyramide</li>
                                <li>91200 Athis Mons - France</li>
                            </ul>
                        </Col>
                        <Col sm={6} md={4}>
                            <h6>INFORMATIONS LÉGALES</h6>
                            <div className="linea-divisora-footer"></div>
                            <ul className="list-unstyled text-small">
                                <li><a className="text-muted color-letter-footer" href="/legaly" rel="noopener noreferrer" target="_blank">Mentions légales</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4} className="container-btn-newletter">
                            <a className="button-newletter" href="/contact">Recevoir notre newsletter</a>
                        </Col>
                        <Col sm={6} md={4}>
                            <ul className="list-unstyled text-small">
                                <li>E-mail: <span className="color-green-main">secretariat@msp-la-pyramide.com</span></li>
                                <li>Téléphone: 01 69 48 46 78</li>
                            </ul>
                        </Col>
                        <Col sm={6} md={4}>
                            <ul className="list-unstyled text-small">
                                <li><a className="text-muted color-letter-footer" href="/policy" rel="noopener noreferrer" target="_blank">Politique de confidentialité</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "20px" }} className="mt-sm-3 mb-sm-3 d-flex justify-content-md-start">
                        <Col sm={12} md={4} lg={4}>
                            <p className='mt-2' style={{ fontSize: "24px" }}>Avec le soutien de:</p>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <div className="container-col-1-logos">
                                <img className="mb-3 img-urps" src="../assets/images/urps.png" alt="urps" />
                                <img className="mb-3 img-arl" src="../assets/images/arl.jpeg" alt="arl" />
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <div className="iledefrance">
                                <img className="mb-3 img-iledefrance" src="../assets/images/logo.svg" alt="Iledefrance" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <p className="mt-4 d-flex justify-content-sm-center justify-content-md-start align-items-center"><span className="mr-2">© Powered by &nbsp;&nbsp; </span><img src="../assets/images/kairos.png" alt="kairos" width="150" /></p>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <div className="container-col-1-logos">
                                <Col>
                                    <img className="mb-3 img-rtc" src="../assets/images/rtc.jpg" alt="rtc" />
                                </Col>
                                <Col style={{justifyContent: 'center'}}>
                                    <Row>
                                        <small>Pour participer à notre enquête de satisfaction, il vous suffit de scanner le QR code suivant:</small>
                                    </Row>
                                    <Row style={{justifyContent: 'center'}}>
                                        <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=BViar8uHeEaMU0Cfl7CDvnfNt89jPxBAhyEO6eX_2spURVdVMVZDQkZaMUg3NFk2Sjc0UVVIR1BHQyQlQCN0PWcu&embed=true" target='_blank' rel="noopener noreferrer">
                                            <img className="mb-3 img-qrc d-block mx-auto" src="../assets/images/qrc.png" alt="qrc" />
                                        </a>
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className='mt-4'>
                            <a className="text-muted " href="https://www.facebook.com/MSP-La-Pyramide-114713840178405/" rel="noopener noreferrer" target="_blank"><img src="../assets/images/facebook.png" alt="facebook" width="32" /></a>
                            <a className="text-muted m-3" href="https://www.twitter.com/MSPLaPyramide" rel="noopener noreferrer" target="_blank"><img src="../assets/images/twitter.png" alt="twitter" width="32" /></a>
                            <a className="text-muted m-3" href="https://www.instagram.com/msplapyramide/" rel="noopener noreferrer" target="_blank"><img src="../assets/images/instagram.png" alt="instagram" width="32" /></a>

                            <a className="text-muted m-3" href="https://youtu.be/u98QEyA_o6Q" rel="noopener noreferrer" target="_blank"><img src="../assets/images/youtube.png" alt="youtube" width="32" /></a>
                            <a className="text-muted m-3" href="https://www.linkedin.com/company/msp-la-pyramide" rel="noopener noreferrer" target="_blank"><img src="../assets/images/linkedin.png" alt="linkendin" width="32" /></a>
                        </Col>
                    </Row>
                </Container>
            </footer>

        )
    }
}
