import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Formik } from 'formik'
import Alert from 'react-bootstrap/Alert'
import * as Yup from 'yup';
import API from '../axios';
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import sha256 from 'sha256'


const validateSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    password: Yup.string()
        .required('Requis')
});

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.alertError = React.createRef();
        this.state = {
            redirect: false
        }
        this.redirectDashboard = this.redirectDashboard.bind(this)
    }

    componentDidMount() {
        this.alertError.current.style.display = 'none';
        setTimeout(function () { sessionStorage.clear() }, 5000);
    }

    responseForRedirect(res) {
        if (res.status === 200) {
            this.setState({ redirect: true })

        }
    }

    redirectDashboard() {
        if (this.state.redirect) {
            return <Redirect to='/dashboard' />
        }
    }

    render() {
        return (
            <div>
                {this.redirectDashboard()}
                <Container className="background-container-login">
                    <Row>
                        <Col>
                            <div className="site-logo" style={{ marginBottom: '30px' }}><Link to="/"><img className="logo-icon" src={'../../assets/images/logo-blue.png'} alt="logo" width="200" /></Link></div>
                        </Col>
                    </Row>
                    <Alert variant="danger" ref={this.alertError}>
                        Un ou plusieurs détails de compte sont incorrects
                    </Alert>
                    {
                        sessionStorage.getItem('messageSuccess') ?
                            <Alert variant="success">
                                {sessionStorage.getItem('messageSuccess')}
                            </Alert> : sessionStorage.getItem('messageSuccessPasswordBo') ?
                                <Alert variant="success">
                                    {sessionStorage.getItem('messageSuccessPasswordBo')}
                                </Alert> : <div></div>
                    }
                    <div className="title-login" >
                        Veuillez indiquer vos informations de connexion
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={validateSchema}
                        onSubmit={(values, { resetForm }) => {
                            API({
                                method: 'post', url: 'team/validate/account', data: {
                                    email: values.email,
                                    password: sha256(values.password),
                                },
                                headers: { 'Content-Type': 'application/json' }
                            }).then(res => {
                                var last_name = res.data.data.last_name !== null ? res.data.data.last_name : "";
                                var name = res.data.data.first_name + " " + last_name;
                                localStorage.setItem('usuario', name);
                                localStorage.setItem('email', res.data.data.email);
                                localStorage.setItem('id', res.data.data.id);
                                this.responseForRedirect(res)

                            }).catch((error) => {
                                resetForm();
                                this.alertError.current.style.display = 'block';
                            });
                        }}
                    >

                        {props => (


                            <form onSubmit={props.handleSubmit} className="container-login">
                                <Row className="col-size-input-login">
                                    <Col>
                                        <div className="form-group">
                                            <label>Renseignez votre adresse email</label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                onChange={props.handleChange}
                                            />
                                            {props.errors.email ? <div style={{ color: 'red' }}>{props.errors.email}</div> : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="col-size-input-login">
                                    <Col>
                                        <div className="form-group">
                                            <label>Entrez votre mot de passe</label>
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                onChange={props.handleChange}
                                            />
                                            {props.errors.password ? <div style={{ color: 'red' }}>{props.errors.password}</div> : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link to="/password" className="link-password">Mot de passe oublié?</Link>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col className="align-center-button">
                                        <Button type="submit" className="mt-3 btn-style-form"><span className="material-icons">keyboard_arrow_right</span><i>Suivant</i></Button>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                </Container>
            </div>
        )
    }
}
