import API from '../../axios';

export const getContactList = async () => {
    let response = await API.get('user/get/contacts')

    return response.data?.data?.map((contact) => {
        return {
            id: contact.id,
            date: new Date(contact.recording).toISOString().split('T')[0],
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone_number: contact.phone_number,
            city: contact.city,
            message: contact.comment,
            subscribed: contact.subscribed
        }
    })
}

export const addContact = async (contact) => {
    return await API({
        method: 'POST',
        url: 'user/add/contact',
        headers: { 'Content-Type': 'application/json' },
        data: {
            firstName: contact.first_name,
            lastName: contact.last_name,
            email: contact.email,
            city: contact.city,
            phoneNumber: contact.phone_number,
            subscribed: contact.subscribed ? 'Y' : 'N',
            reason: 5,
            comment: contact.message
        }
    })
}

export const editContact = async (contact) => {
    return await API({
        method: 'PUT',
        url: 'user/edit/contact?id=' + contact.id,
        headers: { 'Content-Type': 'application/json' },
        data: {
            id: contact.id,
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone_number: contact.phone_number,
            city: contact.city,
            subscribed: contact.subscribed ? 'Y' : 'N'
        }
    })
}

export const deleteContact = async (contact) => {
    return await API({
        method: 'DELETE',
        url: 'user/delete/contact?id=' + contact.id,
        headers: { 'Content-Type': 'application/json' }
    })
}