import React, { Component } from 'react'
import HeaderDashboard from '../../header/HeaderDashboard'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import API from '../../axios';
import * as Yup from 'yup';
import { Formik } from 'formik'
import sha256 from 'sha256'
import Button from 'react-bootstrap/Button'
import User from '../../login/User'
import { Redirect } from 'react-router-dom'
import FooterDashboard from '../../footer/FooterDashboard'

const validateSchema = Yup.object().shape({
    password: Yup.string()
        .required('Requis'),
    newPassword: Yup.string()
        .required('Requis'),
    passwordConfirmation: Yup.string()
        .required('Requis')
        .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre')
});

export default class PasswordDashboard extends Component {
    constructor(props) {
        super(props)
        this.alertError = React.createRef();
        this.state = {
            email: null,
            redirect: false
        }
        this.redirectLogin = this.redirectLogin.bind(this)
    }

    responseForRedirect(res) {
        if (res.status === 200) {
            this.setState({ redirect: true })
            localStorage.removeItem('usuario');
            localStorage.removeItem('email');
            localStorage.removeItem('id');
        }
    }
    redirectLogin() {
        if (this.state.redirect) {
            return <Redirect to='/login' />
        }
    }

    componentDidMount() {
        const id = this.props.match.params.email;
        this.setState({ email: id });
        this.alertError.current.style.display = 'none';
    }

    render() {
        return (
            <div>
                {this.redirectLogin()}
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={2}>
                            <HeaderDashboard />
                        </Col>
                        <Col sm={12} md={12} lg={10}>
                            <User />
                            <Container fluid className="container-password">
                                <div className="password-dashboard-change">
                                    <Alert variant="danger" ref={this.alertError} style={{marginTop:'50px'}}>
                                        Un ou plusieurs détails de compte sont incorrects
                                    </Alert>
                                    <div className="title-login title-password" >Merci de renseigner vos informations</div>
                                    <Formik
                                        initialValues={{
                                            password: '',
                                            newPassword: '',
                                            passwordConfirmation: ''
                                        }}
                                        validationSchema={validateSchema}
                                        onSubmit={(values, { resetForm }) => {
                                            API({
                                                method: 'put', url: 'team/change/password?email=' + this.state.email, data: {
                                                    email: this.state.email,
                                                    password: sha256(values.password),
                                                    newPassword: sha256(values.newPassword)
                                                },
                                                headers: { 'Content-Type': 'application/json' }
                                            }).then(res => {
                                                sessionStorage.setItem('messageSuccessPasswordBo', 'Le mot de passe a été changé')
                                                this.responseForRedirect(res)
                                            }).catch((error) => {
                                                resetForm();
                                                this.alertError.current.style.display = 'block';
                                            });


                                        }}
                                    >
                                        {props => (
                                            <form onSubmit={props.handleSubmit}>
                                                <Row className="col-size-input-login">
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Entrez votre ancien mot de passe</label>
                                                            <input
                                                                id="password"
                                                                name="password"
                                                                type="password"
                                                                className="form-control"
                                                                onChange={props.handleChange}
                                                            />
                                                            {props.errors.password ? <div style={{ color: 'red' }}>{props.errors.password}</div> : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="col-size-input-login">
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Entrez votre nouveau mot de passe</label>
                                                            <input
                                                                id="newPassword"
                                                                name="newPassword"
                                                                type="password"
                                                                className="form-control"
                                                                onChange={props.handleChange}
                                                            />
                                                            {props.errors.newPassword ? <div style={{ color: 'red' }}>{props.errors.newPassword}</div> : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="col-size-input-login">
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Confirmez votre nouveau mot de passe</label>
                                                            <input
                                                                id="passwordConfirmation"
                                                                name="passwordConfirmation"
                                                                type="password"
                                                                className="form-control"
                                                                onChange={props.handleChange}
                                                            />
                                                            {props.errors.passwordConfirmation ? <div style={{ color: 'red' }}>{props.errors.passwordConfirmation}</div> : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col className="align-center-button">
                                                        <Button type="submit" className="mt-3 btn-style-form"><span className="material-icons">keyboard_arrow_right</span><i>Suivant</i></Button>
                                                    </Col>
                                                </Row>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <FooterDashboard/>               
                        </Col>
                    </Row>
                </Container>


                
            </div>
        )
    }
}
