import "./Home.css";
import React from 'react'
import PunchTitle from './PunchTitle';
import { Row } from 'react-bootstrap';
import useSignal from '../hooks/signal';

export default function Landing() {
  const [getLocation, setLocation] = useSignal('location', 'AM');

  const handleToggleLocation = () => {
    if (getLocation() === 'AM') {
      setLocation('CE')
    } else {
      setLocation('AM')
    }
  }

  return (
    <div className="row landing-container justify-content-center">
      <div className="col-md-6">
        <div className='jumbotron' style={{ backgroundImage: `url(../assets/images/principal.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover', maxHeight: 620 }}>
          <div className="container-jumbotron">
          </div>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-center justify-content-center">
        <div>
          <PunchTitle />
          <Row className="justify-content-md-center">
            {getLocation() === 'AM' &&
              <div className="button-primary" onClick={() => handleToggleLocation()}>
                Accédez aux informations de la MSP de Corbeil-Essonnes
              </div>
            }
            {getLocation() === 'CE' &&
              <div className="button-primary" onClick={() => handleToggleLocation()}>
                Accédez aux informations de la MSP de Athis-Mons
              </div>
            }
          </Row>
        </div>
      </div>
    </div>
  )
}
