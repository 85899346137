import React, { Component } from 'react'
import  Header  from '../header/Header'
import  Footer  from '../footer/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Policy extends Component {
    render() {
        return (
            <div>
                <Header/>
                <Container className="container-policy">
                    <Row>
                        <Col>
                            <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
                            <h5>DU SITE</h5>
                            <h5>www.msp-la-pyramide.com</h5>
                            <h5><b>ARTICLE 1:</b> PRÉAMBULE</h5>
                            <p>Cette politique de confidentialité s'applique au site : <span className="color-blue"><b>www.msp-la-pyramide.com.</b></span></p>
                            <p>La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :</p>
                            <p>
                                <ul className="list-type-1">
                                    <li>
                                        La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent être considérées comme données personnelles toutes les données étant susceptibles d'identifier un utilisateur. Il s'agit notamment du prénom et du nom, de l'âge, de l'adresse postale, l'adresse mail, la localisation de l'utilisateur ou encore son adresse IP ;
                                    </li>
                                    <li>
                                        Quels sont les droits des utilisateurs concernant ces données ;
                                    </li>
                                    <li>
                                        Qui est responsable du traitement des données à caractère personnel collectées et traitées ;
                                    </li>
                                    <li>
                                        A qui ces données sont transmises ;
                                    </li>
                                    <li>
                                        Eventuellement, la politique du site en matière de fichiers "cookies".
                                    </li>
                                </ul>
                            </p>
                            <p>Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après :</p>
                            <p className="text-indent color-blue"><b>www.msp-la-pyramide.com</b></p>
                            <h5><b>ARTICLE 2:</b> PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</h5>
                            <p>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent les principes suivants :</p>
                            <p>
                                <ul className="list-type-1">
                                    <li>
                                    	<b>Licéité, loyauté et transparence:</b> les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;
                                    </li>
                                    <li>
                                        <b>Finalités limitées:</b> la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;
                                    </li>
                                    <li>
                                        <b>Minimisation de la collecte et du traitement des données:</b> seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ;
                                    </li>
                                    <li>
                                        <b>Conservation des données réduites dans le temps:</b> les données sont conservées pour une durée limitée, dont l'utilisateur est informé. Lorsque cette information ne peut pas être communiquée, l'utilisateur est informé des critères utilisés pour déterminer la durée de conservation ;
                                    </li>
                                    <li>
                                        <b>Intégrité et confidentialité des données collectées et traitées:</b> le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données collectées.
                                    </li>
                                </ul>
                            </p>
                            <p>Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées:</p>
                            <p>
                                <ul>
                                    <li>
                                    	L'utilisateur a expressément consenti au traitement ;
                                    </li>
                                    <li>
                                    	Le traitement est nécessaire à la bonne exécution d'un contrat ;
                                    </li>
                                    <li>
                                    	Le traitement répond à une obligation légale ;
                                    </li>
                                    <li>
                                    	Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;
                                    </li>
                                    <li>
                                    	Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ;
                                    </li>
                                    <li>
                                        Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.
                                    </li>
                                </ul>
                            </p>
                            <h5><b>ARTICLE 3:</b> DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</h5>
                            <h6 className="padding-botton-title color-blue"><b className="text-indent">A.</b> DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</h6>
                            <p>Les données à caractère personnel collectées sur le site www.msp-la-pyramide.com sont les suivantes:</p>
                            <p>
                                <ul className="list-type-2">
                                    <li>
                                        <span className="color-blue"><b>-</b></span> Prénom-nom
                                    </li>
                                    <li>
                                        <span className="color-blue"><b>-</b></span> Adresse e-mail
                                    </li>
                                    <li>
                                        <span className="color-blue"><b>-</b></span> Adresse postale
                                    </li>
                                    <li>
                                        <span className="color-blue"><b>-</b></span> Ville 
                                    </li>
                                    <li>
                                        <span className="color-blue"><b>-</b></span> Numéro de téléphone
                                    </li>
                                </ul>
                            </p>
                            <p>Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site:</p>
                            <p>
                                <ul className="list-type-2">
                                    <li>
                                        Prise de rendez-vous
                                    </li>
                                    <li>
                                        Demande d’informations
                                    </li>
                                </ul>
                            </p>
                            <p>Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées pour une durée de : 5 ans.</p>
                            <p>Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées uniquement le temps nécessaire pour les finalités poursuivies, conformément aux prescriptions légales. L'Utilisateur peut à tout moment retirer son consentement et demander la suppression de son compte et de ses données personnelles via son tableau de bord.</p>
                            <p>Pour les données traitées en tant que sous-traitant, la conservation des données est garantie jusqu'à leur effacement. Pour les données traitées en tant que Responsable de Traitement, la durée de conversation est fixée à un an pour les données de connexion, et jusqu'à l'effacement du compte par l'utilisateur ou le retrait du consentement enregistré pour les autres cas de figure. </p>
                            <p>La collecte et le traitement des données répondent aux finalités suivantes:</p>
                            <p>
                                <ul className="list-type-2">
                                    <li>
                                        Proposer aux utilisateurs des RDV médicaux
                                    </li>
                                    <li>
                                        Faire des enquêtes de satisfaction facultatives afin d’améliorer la navigation sur le site
                                    </li>
                                </ul>
                            </p>
                            <p>Pour mener des campagnes de communication et d’information après avoir obtenu préalablement le consentement des personnes concernées et, notamment, par son inscription volontaire au service au moyen de son adresse email.</p>
                            <h6 className="padding-botton-title color-blue "><b className="text-indent">B.</b> TRANSMISSION DES DONNÉES A DES TIERS</h6>
                            <p>Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que par l'éditeur du site.</p>
                            <h6 className="padding-botton-title color-blue "><b className="text-indent">C.</b> HÉBERGEMENT DES DONNÉES</h6>
                            <p>Le site <span className="color-blue"><b>www.msp-la-pyramide.com</b></span> est hébergé par: </p>
                            <p>OVH SAS au capital de 10 069 020 € dont le siège social est situé 2 rue Kellermann - 59100 Roubaix - France et immatriculée sous le numéro RCS Lille Métropole 424 761 419 00045</p>
                            <p>Les données collectées et traitées par le site sont exclusivement hébergées et traitées en France.</p>
                            <h5><b>ARTICLE 4:</b> RESPONSABLE DU TRAITEMENT DES DONNÉES</h5>
                            <h6 className="padding-botton-title color-blue"><b className="text-indent">A.</b> LE RESPONSABLE DU TRAITEMENT DES DONNÉES</h6>
                            <p>Le responsable du traitement des données à caractère personnel est : Dr SLIMANI FAROUK. Il peut être contacté de la manière suivante :</p>
                            <p>
                                <ul className="list-type-2">
                                    <li>
                                        Tél.0169484678
                                    </li>
                                    <li>
                                        Du Lundi au Vendredi de 14h à 17h
                                    </li>
                                </ul>
                            </p>
                            <p>Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du traitement des données à caractère personnel.</p>
                            <h6 className="padding-botton-title color-blue"><b className="text-indent">B.</b> OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</h6>
                            <p>Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.</p>
                            <p>Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés. </p>
                            <p>Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site. </p>
                            <p>De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés. </p>
                            <p>Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.</p>
                            <h5><b>ARTICLE 5:</b> DROITS DE L'UTILISATEUR</h5>
                            <p>Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.</p>
                            <p>Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse e-mail.</p>
                            <p>Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.</p>
                            <h6 className="padding-botton-title color-blue"><b className="text-indent">A.</b> PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</h6>
                            <p>
                                <ul className="list-type-3">
                                    <li>
                                        <i>Droit d'accès, de rectification et droit à l'effacement</i>
                                        <p>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :</p>
                                        <p className="text-indent">L'utilisateur doit envoyer un email au responsable du traitement des données personnelles, en précisant l'adresse e-mail de contact qui est fournie plus haut</p>
                                    </li>
                                    <li>
                                        <i>Droit à la portabilité des données</i>
                                        <p>L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :</p>
                                        <p className="text-indent">L'utilisateur doit faire une demande de portabilité de ces données personnelles auprés du responsable du traitement des données en envoyant un e-mail a l'adresse prévue ci-dessus.</p>
                                    </li>
                                    <li>
                                        <i>Droit à la limitation et à l'opposition du traitement des données</i>
                                        <p>L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.</p>
                                        <p>Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante:</p>
                                        <p className="text-indent">L'utilisateur doit faire une demande de limitation au traitement des ces données personnelles auprès du responsable du traitement des données en envoyant un e-mail a l'adresse prévue ci-dessus.</p>
                                    </li>
                                    <li>
                                        <i>Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</i>
                                        <p>Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.</p>
                                    </li>
                                    <li>
                                        <i>Droit de déterminer le sort des données après la mort</i>
                                        <p>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.</p>
                                    </li>
                                    <li>
                                        <i>Droit de saisir l'autorité de contrôle compétente</i>
                                        <p>Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge compétent.</p>
                                    </li>
                                </ul>
                            </p>
                            <h6 className="padding-botton-title color-blue"><b className="text-indent">B.</b> DONNÉES PERSONNELLES DES PERSONNES MINEURES</h6>
                            <p>Conformément aux dispositions de l'article 8 du règlement européen 2016/679 et à la loi Informatique et Libertés, seuls les mineurs âgés de 15 ans ou plus peuvent consentir au traitement de leurs données personnelles.</p>
                            <p>Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un représentant légal sera requis afin que des données à caractère personnel puissent être collectées et traitées.</p>
                            <p>L'éditeur du site se réserve le droit de vérifier par tout moyen que l'utilisateur est âgé de plus de 15 ans, ou qu'il aura obtenu l'accord d'un représentant légal avant de naviguer sur le site.</p>
                            <h5><b>ARTICLE 6:</b> UTILISATION DES FICHIERS "COOKIES"</h5>
                            <p>Dans le cas où le site aura éventuellement recours aux techniques de "cookies".</p>
                            <p>Un "cookie" est un fichier de petite taille (moins de 4 ko), stocké par le site sur le disque dur de l'utilisateur, contenant des informations relatives aux habitudes de navigation de l'utilisateur.</p>
                            <p>Ces fichiers lui permettent de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d'améliorer le service pour le confort de l'utilisateur.</p>
                            <p>Pour l'utilisation de fichiers "cookies" impliquant la sauvegarde et l'analyse de données à caractère personnel, le consentement de l'utilisateur est nécessairement demandé.</p>
                            <p>Ce consentement de l'utilisateur est considéré comme valide pour une durée de 13 (treize) mois maximum. A l'issue de cette période, le site demandera à nouveau l'autorisation de l'utilisateur pour enregistrer des fichiers "cookies" sur son disque dur.</p>
                            <p>
                                <ul className="list-type-3">
                                    <li>
                                        <i>Opposition de l'utilisateur à l'utilisation de fichiers "cookies" par le site</i>
                                        <p>Il est porté à la connaissance de l'utilisateur qu'il peut s'opposer à l'enregistrement de ces fichiers "cookies" en configurant son logiciel de navigation.</p>
                                        <p>Pour information, l'utilisateur peut trouver aux adresses suivantes les démarches à suivre afin de configurer son logiciel de navigation pour s'opposer à l'enregistrement des fichiers "cookies":</p>
                                        <ul className="list-type-1">
                                            <li><b>Chrome:</b> <a href="https://support.google.com/accounts/answer/61416?hl=fr" rel="noopener noreferrer" target="_blank">https://support.google.com/accounts/answer/61416?hl=fr</a></li>
                                            <li><b>Firefox:</b> <a href="https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences" rel="noopener noreferrer" target="_blank">https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences</a></li>
                                            <li><b>Safari:</b> <a href="http://www.apple.com/legal/privacy/fr-ww/" rel="noopener noreferrer" target="_blank">http://www.apple.com/legal/privacy/fr-ww/</a></li>
                                            <li><b>Internet Explorer:</b> <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies" rel="noopener noreferrer" target="_blank">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                                            <li><b>Opera:</b> <a href="http://www.opera.com/help/tutorials/security/cookies/" rel="noopener noreferrer" target="_blank">http://www.opera.com/help/tutorials/security/cookies/</a></li>
                                        </ul>
                                        <p>Dans le cas où l'utilisateur décide de désactiver les fichiers "cookies", il pourra poursuivre sa navigation sur le site. Toutefois, tout dysfonctionnement du site provoqué par cette manipulation ne pourrait être considéré comme étant du fait de l'éditeur du site.</p>
                                    </li>
                                    <li>
                                        <i>Description des fichiers "cookies" utilisés par le site</i>
                                        <p>L'éditeur du site attire l'attention de l'utilisateur sur le fait que les cookies suivants sont utilisés lors de sa navigation</p>
                                    </li>
                                </ul>
                            </p>
                            <h5><b>ARTICLE 7:</b> CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h5>
                            <p>La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée:</p>
                            <p className="text-indent color-blue"><b>www.msp-la-pyramide.com</b></p>
                            <p>L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.</p>
                            <p>Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.</p>
                            <p>Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 24/04/2020.</p>
                            <h5><b>ARTICLE 8:</b> ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h5>
                            <p>En naviguant sur le site, l'utilisateur atteste avoir lu et compris la présente politique de confidentialité et en accepte les conditions, en ce qui concerne plus particulièrement la collecte et le traitement de ses données à caractère personnel, ainsi que l'utilisation de fichiers "cookies" s’il y a lieu.</p>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        )
    }
}
