import React, { Fragment } from 'react'
import { Col } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'

export default function MemberCard({ member, specialties }) {
  return (
    <Fragment>
      {member &&
        <Col key={member.id} sm={12} md={6} className="container-col">
          {member.image_name &&
            <Image src={process.env.REACT_APP_S3_HOST + process.env.REACT_APP_IMAGES_TEAM_PATH + "/" + member.image_name} className="img-medicine-speciallity" />
          }
          {!member.image_name &&
            <Image src='../assets/images/defImage.png' className="img-medicine-speciallity" />
          }
          <p><b>{member.first_name + " " + member.last_name}</b></p>
          <p><b>{specialties.find((sp) => sp.id === member.specialty)?.wording}</b></p>
          <div className="container-description-team">
            {member.description &&
              member.description?.split('\n').map((presentation, item) => (<p key={item}>{presentation}</p>))
            }
          </div>
          {member.link && member.button ? <p className="button-link-team"><a href={member.link} rel="noopener noreferrer" target="_blank">{member.button}</a></p> : <div></div>}
          {member.link2 && member.button2 ? <p className="button-link2-team"><a href={member.link2} rel="noopener noreferrer" target="_blank">{member.button2}</a></p> : <div></div>}
        </Col>

      }

    </Fragment>
  )
}
