import React from 'react'
import Header from '../header/Header'
import Landing from './Landing'
import About from './About'
import Carousel from './Carousel';
import GeneralInfo from './GeneralInfo';
import Footer from '../footer/Footer'
import OpeningHours from './OpeningHours';

export default function Home() {
    return (
        <div>
            <Header />
            <Landing />
            <OpeningHours />
            <Carousel />
            <About />
            <GeneralInfo />
            <Footer />
        </div>
    )

}

