import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function About() {
    return (
        <Container fluid className='mb-4'>
            <Row>
                <Col className="text-presentation">
                    <p>Au carrefour des villes des portes de l'Essonne, la maison de santé pluriprofessionnelle (MSP) La Pyramide accueille des patients de tous âges et de toutes pathologies. A proximité de l'aéroport d'Orly, elle est située le long de la Nationale 7 en face de la Pyramide de Juvisy.</p>
                    <p>Votre MSP réunit une équipe pluriprofessionnelle composée de cinq médecins généralistes, d'un pédiatre, d'une diabétologue, d'un acupuncteur, d'une nutritionniste, d'une ostéopathe et des infirmiers.</p>
                    <p>Riche d'une longue expérience hospitalière et de ville, chacun des membres de l'équipe de la maison de santé La Pyramide se mobilise en faveur de la protection de la petite enfance et de la prévention de la surexposition aux écrans.</p>
                    <p>Votre MSP est également engagée aux côtés de l'Assurance maladie dans la lutte contre le surpoids dont l'obésité infantile avec le programme “Retrouve ton CAP”. L'équipe œuvre quotidiennement pour favoriser la couverture vaccinale et prévenir la perte d'autonomie tout en permettant le maintien à domicile des personnes âgées.</p>
                </Col>
            </Row>
        </Container>
    )
}
