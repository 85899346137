import { useState, useEffect, useCallback } from 'react';

let globalState = {};
let listeners = [];

function useSignal(key, initialValue) {
  const [value, setValue] = useState(() => {
    if (!(key in globalState)) {
      globalState[key] = initialValue;
    }
    return globalState[key];
  });

  useEffect(() => {
    const listener = (newValue) => {
      setValue(newValue);
    };
    listeners.push({ key, listener });
    return () => {
      listeners = listeners.filter(l => l.listener !== listener);
    };
  }, [key]);

  const setValueAndSignal = useCallback((newValue) => {
    globalState[key] = newValue;
    setValue(newValue);
    listeners.forEach(l => {
      if (l.key === key) {
        l.listener(newValue);
      }
    });
  }, [key]);

  return [() => value, setValueAndSignal];
}

export default useSignal;
