import React, { useState, useEffect } from 'react';
import HeaderDashboard from '../../header/HeaderDashboard';
import User from '../../login/User';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterDashboard from '../../footer/FooterDashboard';
import API from '../../axios';
import { Button, Modal } from 'react-bootstrap';
import { Divider } from 'semantic-ui-react';
import './Videos.css';
import YouTube from 'react-youtube';

const VideoList = () => {
  const [videoList, setVideoList] = useState([]);
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [currentVideoTitle, setCurrentVideoTitle] = useState('');
  const [currentVideoLink, setCurrentVideoLink] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showModalAddEdit, setShowModalAddEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showTitleWarning, setShowTitleWarning] = useState(false);
  const [showLinkWarning1, setShowLinkWarning1] = useState(false);
  const [showLinkWarning2, setShowLinkWarning2] = useState(false);

  const youtubeOpts = {
    height: '156',
    width: '256',
    playerVars: { autoplay: 0 }
  };

  useEffect(() => {
    handleRequestVideos();
  }, []);

  const handleCloseRemoveModal = () => {
    setShowModalRemove(false);
  };

  const handleCloseAddEditModal = () => {
    setShowModalAddEdit(false);
    setShowTitleWarning(false);
    setShowLinkWarning1(false);
    setShowLinkWarning2(false);
  };

  const handleChangeTitle = (event) => {
    setCurrentVideoTitle(event.target.value);
    console.log(event.target.value);
  };

  const handleChangeLink = (event) => {
    setCurrentVideoLink(event.target.value);
    console.log(event.target.value);
  };

  const handleValuesToAdd = () => {
    setCurrentVideoId(null);
    setCurrentVideoTitle('');
    setCurrentVideoLink('');
    setShowModalAddEdit(true);
    setIsEditing(false);
  };

  const handleValuesToEdit = (video) => {
    setCurrentVideoId(video.id);
    setCurrentVideoTitle(video.title);
    setCurrentVideoLink(video.link);
    setIsEditing(true);
    setShowModalAddEdit(true);
  };

  const handleValuesToRemove = (video) => {
    setCurrentVideoId(video.id);
    setShowModalRemove(true);
  };

  const handleRequestVideos = () => {
    API.get('/video/get/all').then((response) => {
      const data = response.data.data.map((video) => ({
        id: video.id,
        title: video.title,
        link: video.link,
        code: video.code
      }));
      setVideoList(data);
    });
  };

  const handleSubmitAddEdit = () => {
    let titleEmpty = !currentVideoTitle;
    let linkEmpty = !currentVideoLink;
    let linkInvalid = false;
    const regExp1 = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

    const match1 = currentVideoLink.match(regExp1);
    if (!match1 || match1[2].length !== 11) linkInvalid = true;

    if (!titleEmpty && !linkEmpty && !linkInvalid) {
      const url = isEditing ? '/video/edit' : '/video/create';
      API.post(url, { id: currentVideoId, title: currentVideoTitle, link: currentVideoLink })
        .then(() => {
          handleRequestVideos();
          handleCloseAddEditModal();
        });
    } else {
      setShowTitleWarning(titleEmpty);
      setShowLinkWarning1(linkEmpty);
      setShowLinkWarning2(!linkEmpty && linkInvalid);
    }
  };

  const handleRemoveVideo = () => {
    API.delete(`/video/remove?id=${currentVideoId}`).then(() => {
      handleRequestVideos();
      setShowModalRemove(false);
    });
  };

  return (
    <div style={{ margin: '0' }}>
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={2}>
            <HeaderDashboard />
          </Col>
          <Col sm={12} md={12} lg={10}>
            <User />
            <Row className='table-container'>
              <Container>
                <div className='add-item-button' onClick={handleValuesToAdd}>
                  <img src="../../assets/images/add.png" alt="icon add" />
                </div>
                <Divider></Divider>
                <table className='full-width'>
                  <thead className='header-table'>
                    <tr>
                      <th className='col-25'>Titre</th>
                      <th className='col-30'>Lien</th>
                      <th className='col-30'>Aperçu</th>
                      <th className='col-15'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videoList.map((video, index) => (
                      <tr key={index}>
                        <td>{video.title}</td>
                        <td>{video.link}</td>
                        <td>
                          <YouTube videoId={video.code} opts={youtubeOpts} />
                        </td>
                        <td>
                          <span className='clickable action-icon' onClick={() => handleValuesToEdit(video)}>
                            <i className="material-icons size-icono">edit</i>
                          </span>
                          <span className='clickable action-icon' onClick={() => handleValuesToRemove(video)}>
                            <i className="material-icons size-icono">delete</i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Container>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <FooterDashboard />
          </Col>
        </Row>
      </Container>

      <Modal show={showModalAddEdit} onHide={handleCloseAddEditModal}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Publier la vidéo' : 'Ajouter une vidéo'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <label className='col-md-12'>
                Titre *:
                <input
                  className='form-control mb-2'
                  type="text"
                  value={currentVideoTitle}
                  onChange={handleChangeTitle}
                />
                {showTitleWarning ? <span className='validation-warning'>Champ requis</span> : null}
              </label>
            </Row>
            <Row>
              <label className='col-md-12'>
                Lien:
                <input
                  className='form-control mb-2'
                  type="text"
                  value={currentVideoLink}
                  onChange={handleChangeLink}
                />
                {showLinkWarning1 ? <span className='validation-warning'>Champ requis</span> : null}
                {showLinkWarning2 ? <span className='validation-warning'>URL YouTube non valide</span> : null}
              </label>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddEditModal}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleSubmitAddEdit}>
              Confirmer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showModalRemove} onHide={handleCloseRemoveModal}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title>Voulez-vous vraiment supprimer la vidéo ?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRemoveModal}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleRemoveVideo}>
              Confirmer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default VideoList;
