import React, { Component } from "react";
import MapGL, { NavigationControl, Marker, Popup } from "react-map-gl";
import { Icon } from "semantic-ui-react";
const TOKEN =
  "pk.eyJ1IjoiYWJoaWxhc2hhLXNpbmhhIiwiYSI6ImNqdzFwYWN1ajBtOXM0OG1wbHAwdWJlNmwifQ.91s73Dy03voy-wPZEeuV5Q";
const navStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "1px"
};

const markerList1 = {lat: 48.6979999, long: 2.3713999, info: "Nous sommes situés ici"};

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 48.6979999,
        longitude: 2.3712999,
        zoom: 16,
        bearing: 0,
        pitch: 0,
        width: "100%",
        height: "500px"
      },
      popupInfo: null
    };
  }

  renderPopup() {
    return (
      this.state.popupInfo && (
        <Popup
          tipSize={15}
          anchor="bottom-right"
          longitude={markerList1.long}
          latitude={markerList1.lat}
          onMouseLeave={() => this.setState({ popupInfo: null })}
        >
          <p style={{
            color:"rgba(8, 52, 84, 0.9)",
            width:'180px',
            textAlign:"center",
            marginTop:"10px",
            fontSize:"16px",
            height:"10px"
            }}>
            <b>{markerList1.info}</b>
          </p>
        </Popup>
      )
    );
  }

  

  render() {
    const { viewport } = this.state;
    return (
      <MapGL
        {...viewport}
        onViewportChange={viewport => this.setState({ viewport })}
        mapStyle="mapbox://styles/mapbox/streets-v10"
        mapboxApiAccessToken={TOKEN}
      >
        <div className="nav" style={navStyle}>
          <NavigationControl
            onViewportChange={viewport => this.setState({ viewport })}
          />
              <div>
                {" "}
                <Marker longitude={markerList1.long} latitude={markerList1.lat}>
                  <Icon
                    style={{color:"#083454"}}
                    name="map marker alternate"
                    size="big"
                    onMouseEnter={() => this.setState({ popupInfo: true })}
                    onMouseLeave={() => this.setState({ popupInfo: null })}
                  />
                </Marker>{" "}
                {this.renderPopup()}
              </div>
        </div>
      </MapGL>
    );
  }
}
