import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Alert from 'react-bootstrap/Alert'
import API from '../../axios';


export default class DataTables extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: true,
            offset: 0,
            data: [],
            perPage: 15,
            currentPage: 0,
            deleteItem: null,
            btn_create_post: false
        }
        this.generateHeaders = this.generateHeaders.bind(this);
        this.generateRows = this.generateRows.bind(this);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    componentDidMount() {
        this.setState({ action: !this.props.action, btn_create_post: !this.props.btn_create })
        setTimeout(function () { sessionStorage.clear() }, 5000);
    }

    generateHeaders() {
        let cols = this.props.columns;  // [{key, label}]
        return cols.map(function (colData) {
            return (
                <th className="column-datatable" key={colData.key}>
                    {colData.name}
                </th>
            );
        });
    }

    generateRows() {
        let fieldChecbox = this.props.fieldChecbox;
        let routeDelete = this.props.routeDelete;
        let btn_class_action = this.state.action ? "hiddenColumn" : "visibleColumn";
        const slice = this.props.rows.slice(this.state.offset, this.state.offset + this.state.perPage)

        let cols = this.props.columns,  // [{key, label}]
            data = slice;
        return data.map(function (item, i) {
            var cells = cols.map(function (colData) {
                return (
                    <td key={colData.key}>
                        {Object.values(item)[colData.key]}
                    </td>);
            });
            return (<tr className="row-datatable" key={i}>
                {cells}
                <td>{
                    fieldChecbox[i].checked === true ? "Oui" : "Non"
                }</td>
                <td className={btn_class_action}>
                    <NavLink className="btn-view-database" to={'/newsletterView/' + item.id}><i className="material-icons size-icono">remove_red_eye</i></NavLink>
                    <NavLink className="btn-edit-database" to={'/newsletterEdit/' + item.id}><i className="material-icons size-icono">edit</i></NavLink>
                    <NavLink className="btn-delete-database" to="#!" onClick={() => {
                        var deleteItem = API.get('post/get/byCode?code=' + item.id).then((response) => {
                            return response.data.data.image_name
                        });
                        API({
                            method: 'delete', url: routeDelete + "?code=" + item.id, data: {
                                code: item.id,
                                imageName: deleteItem
                            }
                        }).then(res => {
                            sessionStorage.setItem('messageDelete', "effacer l'article")
                            setTimeout(window.location.reload(), 3000)
                        }).catch(error => {
                            console.log(error)
                        })
                    }}><i className="material-icons size-icono">delete</i></NavLink></td>
            </tr>);
        });
    }


    render() {
        let headers = this.generateHeaders();
        let rows = this.generateRows();
        let btn_class_action = this.state.action ? "hiddenColumn" : "visibleColumn column-datatable";
        let btn_create_post = this.state.btn_create_post ? "hidden-btn-create" : "container-btn-create-post"
        return (
            <>
                <div className="container-datatable">
                    <div className="container-table">
                        {
                            sessionStorage.getItem('messageCreate') ?
                                <Alert className="alert-datatable" variant="success">
                                    {sessionStorage.getItem('messageCreate')}
                                </Alert> :
                                sessionStorage.getItem('messageEdit') ?
                                    <Alert className="alert-datatable" variant="success">
                                        {sessionStorage.getItem('messageEdit')}
                                    </Alert> :
                                    sessionStorage.getItem('messageDelete') ?
                                        <Alert className="alert-datatable" variant="danger">
                                            {sessionStorage.getItem('messageDelete')}
                                        </Alert> : <div></div>
                        }
                        <div className={btn_create_post}>
                            <NavLink to='/newsletter/create' className="btn-create-post"><img src="../../assets/images/add.png" alt="icon add" /></NavLink>
                        </div>
                        <table className="full-width">
                            <thead >
                                <tr className="container-column-sm">
                                    {headers}
                                    <th className="column-datatable">{this.props.nameFieldCheckbox}</th>
                                    <th className={btn_class_action}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                    <div className="container-pagination">
                        <ReactPaginate
                            previousLabel={"Précédent"}
                            nextLabel={"Suivant"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(this.props.rows.length / this.state.perPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick.bind(this)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    </div>
                </div>
            </>
        )
    }
}
