import * as Yup from 'yup';

export const validateSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, 'Trop court!')
        .max(16, 'Trop long!')
        .required('Requis'),
    last_name: Yup.string()
        .min(2, 'Trop court!')
        .max(16, 'Trop long!')
        .required('Requis'),
    phone_number: Yup.number()
        .typeError("Cela ne ressemble pas à un numéro de téléphone")
        .positive('Numéro invalide')
        .required('Requis'),
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    city: Yup.string()
        .required('Requis')
        .min(2, 'Trop court!')
        .max(70, 'Trop long!'),
    message: Yup.string()
        .required('Requis')
        .min(10, 'Trop court!')
        .max(500, 'Trop long!')
});