import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class FooterDashboard extends Component {
    render() {
        return (
            <footer className="container-fluid py-5 color-letter-footer footer-color-background">
                <Container>
                    <Row>
                        <Col sm={12} md={4}>
                            <div id="logo-footer"><img className="logo-icon" src="../../assets/images/logo-blue.png" alt="logo" width="170" /></div>
                        </Col>
                        <Col sm={6} md={4}>
                            <h6>NOUS CONTACTER</h6>
                            <div className="linea-divisora-footer"></div>
                            <ul className="list-unstyled text-small">
                                <li>Maison de santé pluriprofessionnelle La Pyramide</li>
                                <li>91200 Athis Mons - France</li>
                            </ul>
                        </Col>
                        <Col sm={6} md={4}>
                            <h6>INFORMATIONS LÉGALES</h6>
                            <div className="linea-divisora-footer"></div>
                            <ul className="list-unstyled text-small">
                                <li><a className="text-muted color-letter-footer" href="/legaly">Mentions légales</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4} className="container-btn-newletter">
                            <a className="button-newletter" href="/contact">Recevoir notre newsletter</a>
                        </Col>
                        <Col sm={6} md={4}>
                            <ul className="list-unstyled text-small">
                                <li>E-mail: <span className="color-green-main">secretariat@msp-la-pyramide.com</span></li>
                                <li>Téléphone: 01 69 48 46 78</li>
                            </ul>
                        </Col>
                        <Col sm={6} md={4}>
                            <ul className="list-unstyled text-small">
                                <li><a className="text-muted color-letter-footer" href="/policy">Politique de confidentialité</a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} md={4}>
                            <a className="button-newletter-sm" href="/contact">Recevoir notre newsletter</a>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "20px" }} className="mt-sm-3 mb-sm-3 d-flex justify-content-md-start">
                        <Col sm={12} md={3} className="d-flex justify-content-sm-center justify-content-md-start align-items-md-center">
                            <p style={{ fontSize: "24px" }}>Avec le soutien de:</p>
                        </Col>
                        <Col sm={12} md={9} className="container-eps-arl">
                            <div className="container-col-1-logos">
                                <img className="mb-3 img-urps" src="../assets/images/urps.png" alt="urps" />
                                <img className="mb-3 img-arl" src="../assets/images/arl.jpeg" alt="arl" />
                            </div>
                            <div className="iledefrance">
                                <img className="mb-3 img-iledefrance" src="../assets/images/logo.svg" alt="Iledefrance" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <p className="d-flex justify-content-sm-center justify-content-md-start align-items-center"><span className="mr-2">© 2020 design by </span><img src="../assets/images/kairos.png" alt="kairos" width="150" /></p>
                        </Col>
                        <Col sm={12} md={4} lg={4}>

                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <ul className="mt-sm-3 list-unstyled text-small list-group-horizontal d-flex justify-content-md-start justify-content-sm-center">
                                <li>
                                    <a className="text-muted" href="https://www.facebook.com/MSP-La-Pyramide-114713840178405/" rel="noopener noreferrer" target="_blank"><img src="../assets/images/facebook.png" alt="facebook" width="32" /></a>
                                    <a className="text-muted ml-lg-4 ml-md-2 ml-sm-3" href="https://www.twitter.com/MSPLaPyramide" rel="noopener noreferrer" target="_blank"><img src="../assets/images/twitter.png" alt="twitter" width="32" /></a>
                                    <a className="text-muted ml-lg-4 ml-md-2 ml-sm-3" href="https://www.instagram.com/msplapyramide/" rel="noopener noreferrer" target="_blank"><img src="../assets/images/instagram.png" alt="instagram" width="32" /></a>

                                    <a className="text-muted ml-lg-4 ml-md-2 ml-sm-3" href="https://youtu.be/u98QEyA_o6Q" rel="noopener noreferrer" target="_blank"><img src="../assets/images/youtube.png" alt="youtube" width="32" /></a>
                                    <a className="text-muted ml-lg-4 ml-md-2 ml-sm-3" href="https://www.linkedin.com/company/msp-la-pyramide" rel="noopener noreferrer" target="_blank"><img src="../assets/images/linkedin.png" alt="linkendin" width="32" /></a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>

        )
    }
}
