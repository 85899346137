import './Home.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { useScreenSize } from '../hooks/screen.size';
import VideoModal from './VideoModal';

export default function VideoThumbnail({ id, title, imgPath, pauseSlider }) {
  const [options, setOptions] = useState({ height: '400', width: '600' })
  const [showModal, setShowModal] = useState(false)
  const screenSize = useScreenSize()

  useEffect(() => {
    if (!screenSize) return
    if (screenSize.width < 400) {
      setOptions({ height: '100', width: '200' })
      return
    }
    if (screenSize.width < 600) {
      setOptions({ height: '190', width: '300' })
      return
    }
    if (screenSize.width < 900) {
      setOptions({ height: '285', width: '450' })
      return
    }
    setOptions({ height: '380', width: '600' })
  }, [screenSize]);

  const handleThumbClick = () => {
    pauseSlider()
    setShowModal(!showModal)
  }

  return (
    <Fragment>
      <div className="video" style={{padding: screenSize.width > 600 ? 30 : 12}}>
        <Stack direction={screenSize.width > 900 ? 'horizontal' : 'vertical'} style={{ justifyContent: 'center' }} gap={4}>
          <div className='video-container'>
            <img
              src={imgPath}
              width={options.width}
              height={options.height}
              alt={title}
              onClick={handleThumbClick}
            />
          </div>
          <div style={{ width: screenSize.width > 600 ? parseFloat(options.width) : '100%' }}>
            <p className='video-text' style={{ fontSize: screenSize.width > 600 ? 30 : 16 }}>{title}</p>
          </div>
        </Stack>
      </div>
      <VideoModal
        id={id}
        title={title}
        show={showModal}
        setShow={setShowModal}
      />
    </Fragment>
  )
}
