import React, { useEffect, useState } from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import { Formik, Form, Field } from "formik";
import { validateSchema } from './ContactSchema';
import { addContact } from './ContactApi';

export default function AddContact(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show)
    }, [props])

    const closeModal = () => {
        setShow(false)
        props.closeFromChild()
    }

    const submitForm = async (data) => {
        let response = await addContact(data)
        if (response && response.status === 200) closeModal()
    }

    return (
        <Modal
            show={show}
            size="lg"
            centered
            onHide={closeModal}
            onExit={closeModal}
        >
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    city: '',
                    subscribed: false,
                    message: ''
                }}
                validationSchema={validateSchema}
                onSubmit={async (data) => await submitForm(data)}
            >
                {({ errors, touched }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Ajouter un nouvel abonné</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                <Row>
                                    <label className='col-md-6'>
                                        Nom *:
                                        <Field className='form-control mb-2' type="text" name='first_name' />
                                        {touched.first_name && errors.first_name ? (<div className='validation-error'>{errors.first_name}</div>) : null}
                                    </label>
                                    <label className='col-md-6'>
                                        Prénom *:
                                        <Field className='form-control mb-2' type="text" name='last_name' />
                                        {touched.last_name && errors.last_name ? (<div className='validation-error'>{errors.last_name}</div>) : null}
                                    </label>
                                </Row>
                                <Row>
                                    <label className='col-md-6'>
                                        Email *:
                                        <Field className='form-control mb-2' type="text" name='email' />
                                        {touched.email && errors.email ? (<div className='validation-error'>{errors.email}</div>) : null}
                                    </label>
                                    <label className='col-md-6'>
                                        Téléphone:
                                        <Field className='form-control mb-2' type="text" name='phone_number' />
                                        {touched.phone_number && errors.phone_number ? (<div className='validation-error'>{errors.phone_number}</div>) : null}
                                    </label>
                                </Row>
                                <Row>
                                    <label className='col-md-6'>
                                        Ville *:
                                        <Field className='form-control mb-2' type="text" name='city' />
                                        {touched.city && errors.city ? (<div className='validation-error'>{errors.city}</div>) : null}
                                    </label>
                                    <label className='col-md-6'>
                                        Commentaire *:
                                        <Field className='form-control mb-2' as="textarea" name='message' rows='1' />
                                        {touched.message && errors.message ? (<div className='validation-error'>{errors.message}</div>) : null}
                                    </label>
                                </Row>
                                <Row>
                                    <label className='col-md-12'>
                                        <Field className='small-margin-right' type="checkbox" name='subscribed' />
                                        J'accepte de recevoir les actualités de la MSP La Pyramide et souhaite m'inscrire à la Newsletter.
                                    </label>
                                </Row>
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>Annuler</Button>
                            <Button type='submit'>Confirmer</Button>
                        </Modal.Footer>
                    </Form>
                )}

            </Formik>
        </Modal>

    )
}
